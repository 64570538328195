import {
  Box,
  Button,
  makeStyles,
  Snackbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";

import { apiCall } from "../../api/utils";
import { endpoints } from "../../api/_config";
import { Alert } from "@material-ui/lab";
import { func, string, shape, arrayOf } from "prop-types";
import { Add } from "@material-ui/icons";
import EditRow from "./EditRow";

const useStyle = makeStyles((theme) => ({
  editRow: {
    marginBottom: theme.spacing(1),
    borderBottom: "1px solid lightgrey",
  },
  aliasList: {
    maxHeight: "300px",
    overflowY: "auto",
  },
  addButtonWrap: {
    textAlign: "right",
    margin: `${theme.spacing(1)}px 0`,
  },
}));

const propTypes = {
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
  activeRetailer: shape({
    Name: string,
    Id: string,
    RetailerStoreType: arrayOf(shape({})),
    RetailerAlias: arrayOf(shape({})),
  }).isRequired,
};

const EditStoreType = ({
  handleUpdate,
  activeRetailer: { Id, Name, RetailerStoreType } = {},
}) => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const [error, setError] = useState(null);
  const [addStoreTypeOpen, setStoreTypeOpen] = useState(false);

  const saveEdit = async (name, id) => {
    try {
      const endpoint = endpoints.retailer.updateStoreType.replace(
        ":StoreTypeId:",
        id
      );
      const { error } = await apiCall(endpoint, { Name: name.trim() });
      handleUpdate();
      if (error) setError(error.message);
    } catch (e) {
      console.error(e);
      setError(e.message);
    }
  };

  const saveNew = async (name, id) => {
    try {
      const endpoint = endpoints.retailer.addStoreType.replace(
        ":RetailerId:",
        id
      );
      const { error } = await apiCall(endpoint, { Name: name });
      setStoreTypeOpen(false);
      handleUpdate();
      if (error) setError(error.message);
    } catch (e) {
      console.error(e);
      setError(e.message);
    }
  };

  return (
    <>
      <Typography variant="h4">{Name}</Typography>
      {addStoreTypeOpen ? (
        <EditRow
          id={Id}
          onSave={saveNew}
          onCancel={() => setStoreTypeOpen(false)}
          initialInput=""
          initialEdit
          fieldLabel="Add"
        />
      ) : (
        <Box className={classes.addButtonWrap}>
          <Button
            startIcon={<Add />}
            onClick={() => setStoreTypeOpen(true)}
            color="primary"
            variant="contained"
          >
            Store Type
          </Button>
        </Box>
      )}

      <Box className={classes.aliasList}>
        {RetailerStoreType.map(({ Id: typeId, Name: typeName }) => (
          <EditRow
            key={typeId}
            onSave={saveEdit}
            id={typeId}
            initialInput={typeName}
          />
        ))}

        <Snackbar open={error} onClose={() => setError(null)}>
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      </Box>
    </>
  );
};

EditStoreType.propTypes = propTypes;

export default EditStoreType;
