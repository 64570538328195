import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { string, node, bool, func } from "prop-types";

const propTypes = {
  title: string,
  children: node.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  onCancel: func,
  onProceed: func,
  cancelText: string,
  proceedText: string,
};

const defaultProps = {
  title: null,
  cancelText: "Cancel",
  proceedText: "Proceed",
};

const WrappedDialog = ({
  title,
  children,
  open,
  onClose,
  onCancel,
  onProceed,
  cancelText,
  proceedText,
}) => {
  return (
    <Dialog className="wrapped-dialog" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onCancel && (
          <Button
            className="wrapped-dialog__cancel"
            color="secondary"
            variant="outlined"
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        )}
        {onProceed && (
          <Button
            className="wrapped-dialog__proceed"
            color="primary"
            variant="contained"
            onClick={onProceed}
          >
            {proceedText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

WrappedDialog.propTypes = propTypes;
WrappedDialog.defaultProps = defaultProps;

export default WrappedDialog;
