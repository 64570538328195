import { makeStyles, useTheme } from "@material-ui/core";
import { string, node } from "prop-types";
import React from "react";

const useStyle = makeStyles({
  wrapper: ({ theme, color }) => ({
    border: "1px solid",
    borderColor: theme.palette[color].main,
    padding: theme.typography.h2.fontSize,
    margin: `${theme.spacing(4)}px 0`,
    position: "relative",
  }),
  title: ({ theme }) => ({
    position: "absolute",
    top: `calc(${theme.typography.h2.fontSize} * -0.5)`,
    left: "50%",
    transform: "translateX(-50%)",
    background: "white",
    padding: `0 ${theme.spacing(3)}px`,
    fontSize: theme.typography.h2.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
  }),
});

const propTypes = {
  children: node.isRequired,
  title: string.isRequired,
  color: string,
};

const defaultProps = {
  color: "grey",
};

const WrappedFieldSet = ({ children, title, color }) => {
  const theme = useTheme();
  const classes = useStyle({ theme, color });
  return (
    <section className={`wrapped-fieldset ${classes.wrapper}`}>
      <span className={classes.title}>{title}</span>
      {children}
    </section>
  );
};

WrappedFieldSet.propTypes = propTypes;
WrappedFieldSet.defaultProps = defaultProps;

export default WrappedFieldSet;
