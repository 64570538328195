import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { endpoints } from "../api/_config";
import ToolTipCell from "../ui-components/DataGridCells/ToolTipCell";
import WrappedDataGrid from "../ui-components/WrappedDataGrid";
import AddIcon from "@material-ui/icons/Add";
import WrappedModal from "../ui-components/WrappedModal";
import AddRetailer from "../ui-components/Retailer/AddRetailer";
import { useLocation } from "react-router";

import RetailerActions from "../ui-components/DataGridCells/RetailerActions";
import Meta from "../ui-components/Meta";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
  },
}));

const Retailers = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [addRetailerOpen, setAddRetailerOpen] = useState(false);
  const [triggeredRefresh, setTriggeredRefresh] = useState(false);

  const [initialFilter, setInitialFilter] = useState();
  const [waitForInitialFilter, setWaitForInitialFilter] = useState(true);

  const { search } = useLocation();

  const params = new URLSearchParams(search);

  useEffect(() => {
    const mode = params.get("mode");
    const value = params.get("value");
    const field = params.get("field");
    switch (mode) {
      case "filter":
        setInitialFilter({
          items: [
            {
              columnField: field,
              operatorValue: "contains",
              value,
            },
          ],
        });

        break;
      default:
        setWaitForInitialFilter(false);
        break;
    }
  }, [search]);

  const nameFormatter = (param) => {
    const names = param.value.map(({ Name }) => Name);
    return names.join(", ");
  };

  const handleClose = () => {
    setAddRetailerOpen(false);
    setTriggeredRefresh((prev) => !prev);
  };

  const handleRefresh = () => {
    setTriggeredRefresh((prev) => !prev);
  };

  const columnDefs = [
    { field: "Id", hide: true },
    {
      field: "Name",
      flex: 1,
      renderCell: ToolTipCell,
    },
    {
      field: "RetailerAlias",
      headerName: "Aliases",
      flex: 1,
      // eslint-disable-next-line react/display-name
      renderCell: (param) => (
        <ToolTipCell value={nameFormatter(param)} whiteSpace="break-space" />
      ),
      filterable: false,
      sortable: false,
    },
    {
      field: "RetailerStoreType",
      headerName: "Store Types",
      flex: 1,
      // eslint-disable-next-line react/display-name
      renderCell: (param) => <ToolTipCell value={nameFormatter(param)} />,
      filterable: false,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 140,
      // eslint-disable-next-line react/display-name
      renderCell: (param) => (
        <RetailerActions param={param} handleRefresh={handleRefresh} />
      ),
      sortable: false,
      filterable: false,
    },
  ];
  return (
    <Container maxWidth="lg">
      <Meta title="Retailers" />
      <Box className={classes.sectionHeader}>
        <Typography variant="h2">Retailers</Typography>
        <Button
          onClick={() => setAddRetailerOpen(true)}
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
        >
          Add Retailer
        </Button>
      </Box>

      <WrappedDataGrid
        idField="Id"
        endpoint={endpoints.retailer.all}
        columnDefinitions={columnDefs}
        triggeredRefresh={triggeredRefresh}
        waitForInitialFilter={waitForInitialFilter}
        initialFilter={initialFilter}
        showColumnToolbar
        showFilterToolbar
        options={{ rowHeight: 130 }}
      />
      <WrappedModal
        name="add-retailer"
        open={addRetailerOpen}
        handleClose={handleClose}
      >
        <AddRetailer handleClose={handleClose} name={params.get("name")} />
      </WrappedModal>
    </Container>
  );
};

export default Retailers;
