import React from "react";
import { makeStyles, Modal, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

const useStyle = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "white",
    position: "absolute",
    width: "60%",
    maxWidth: theme.breakpoints.values.sm,
    maxHeight: "90%",
    overflow: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  closeBar: { display: "flex", justifyContent: "flex-end" },
  close: {
    cursor: "pointer",
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
}));

const propTypes = {
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const WrappedModal = ({ name, open, handleClose, children, ...props }) => {
  const theme = useTheme();
  const classes = useStyle(theme);

  return (
    <Modal
      className="wrapped-modal"
      open={open}
      onClose={handleClose}
      aria-labelledby={name}
      {...props}
    >
      <div className={`wrapped-modal__wrapper ${classes.wrapper}`}>
        <div className={`wrapped-modal__closeBar ${classes.closeBar}`}>
          <CloseIcon
            className={`wrapped-modal__close ${classes.close}`}
            onClick={handleClose}
          />
        </div>
        <div className={`wrapped-modal__content ${classes.content}`}>
          {children}
        </div>
      </div>
    </Modal>
  );
};

WrappedModal.propTypes = propTypes;
export default WrappedModal;
