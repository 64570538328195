import {
  Button,
  Grid,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { apiCall } from "../../api/utils";
import { endpoints } from "../../api/_config";
import { Alert } from "@material-ui/lab";
import { func, string } from "prop-types";

const useStyle = makeStyles((theme) => ({
  textInput: {
    width: "100%",
  },
  inputWrap: {
    margin: `${theme.spacing(1)}px 0`,
  },
  submitButtonWrap: {
    textAlign: "right",
  },
}));

const propTypes = {
  handleClose: func.isRequired,
  name: string,
};

const defaultProps = {
  name: "",
};

const AddRetailer = ({ handleClose, name }) => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const [formInput, setFormInput] = useState({
    Name: name,
    StoreTypes: "",
    Aliases: "",
  });
  const [error, setError] = useState(null);

  const formatString = (input = "") =>
    input
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item !== ""); //remove empty values

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        Name: formInput.Name,
        StoreTypes: formatString(formInput.StoreTypes),
        Aliases: formatString(formInput.Aliases),
      };

      await apiCall(endpoints.retailer.add, body);
      handleClose();
    } catch (e) {
      setError(e.message);
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name) {
      setFormInput({ ...formInput, [name]: value });
    }
  };

  return (
    <form onSubmit={handleSubmit} data-testid="form">
      <Typography variant="h2">Add Retailer</Typography>
      <Grid
        className={classes.inputWrap}
        container
        direction="column"
        spacing={1}
      >
        <Grid item xs>
          <TextField
            className={classes.textInput}
            id="name"
            name="Name"
            label="Retailer"
            variant="outlined"
            value={formInput.Name}
            onChange={handleInputChange}
            required
            data-testid="field-retailer"
          />
        </Grid>
        <Grid item xs>
          <TextField
            className={classes.textInput}
            id="types"
            name="StoreTypes"
            label="Store Types"
            variant="outlined"
            placeholder="Comma separated list"
            multiline
            value={formInput.StoreTypes}
            onChange={handleInputChange}
            data-testid="field-store-types"
          />
        </Grid>
        <Grid item xs>
          <TextField
            className={classes.textInput}
            id="aliases"
            name="Aliases"
            label="Aliases"
            variant="outlined"
            placeholder="Comma separated list"
            multiline
            value={formInput.Aliases}
            onChange={handleInputChange}
            data-testid="field-aliases"
          />
        </Grid>
        <Grid item xs className={classes.submitButtonWrap}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            data-testid="submit"
          >
            Add Retailer
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        data-testid="error-bar"
        open={error}
        onClose={() => setError(null)}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </form>
  );
};

AddRetailer.propTypes = propTypes;
AddRetailer.defaultProps = defaultProps;

export default AddRetailer;
