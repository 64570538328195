import Axios from "axios";
import { endpoints } from "./_config";
import { msalInstance, loginRequest } from "./msal";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export async function getDefaultHeaders() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });

  const headers = {
    Authorization: `Bearer ${response.accessToken}`,
    "Content-Type": "application/json",
  };

  return headers;
}

export const apiCall = async (endpoint, body = {}) => {
  const output = { data: [], error: null };
  let response;
  try {
    var headers = await getDefaultHeaders();

    const options = {
      headers: headers,
    };

    response = await Axios.post(endpoint, body, options);
    output.data = response.data;
    return output;
  } catch (e) {
    console.error(e);
    output.error = e;
    return output;
  }
};

export async function uploadJsonFile(
  { file, dataProvider, reference },
  setProgress
) {
  var headers = await getDefaultHeaders();

  var jsonDetails = JSON.stringify({
    File: await toBase64(file),
    Name: file.name,
    Type: file.type,
    Size: file.size,
    DataProviderId: dataProvider,
    Reference: reference,
  });

  const options = {
    headers: headers,
    onUploadProgress: (data) => {
      const progress = Math.round((100 * data.loaded) / data.total); // number between 0 and 100
      setProgress(progress);
    },
  };

  return await Axios.post(endpoints.uploadJson, jsonDetails, options);
}
