import React from "react";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";

import WrappedDataGrid from "../ui-components/WrappedDataGrid";
import { endpoints } from "../api/_config";

import Meta from "../ui-components/Meta";
import ToolTipCell from "../ui-components/DataGridCells/ToolTipCell";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
  },
}));

const Locations = () => {
  const classes = useStyles();
  const addressFormatter = ({
    row: { Address1, Address2, Address3, Town, County, Postcode, Country } = {},
  }) => {
    const addressList = [
      Address1,
      Address2,
      Address3,
      Town,
      County,
      Postcode,
      Country,
    ];

    const address = addressList
      .filter((line) => typeof line === "string" && line !== "")
      .join(", ");
    return address;
  };

  const columnDefs = [
    { field: "Name", renderCell: ToolTipCell, flex: 1 },
    { field: "RetailerStoreNumber", headerName: "Store Number", flex: 1 },
    {
      field: "Postcode",
      headerName: "Address",
      flex: 3,
      // eslint-disable-next-line react/display-name
      renderCell: (param) => <ToolTipCell value={addressFormatter(param)} />,
    },
    { field: "Enabled", flex: 1 },
  ];

  return (
    <>
      <Container maxWidth="lg">
        <Meta title="Locations" />
        <Box className={classes.sectionHeader}>
          <Typography variant="h2">Locations</Typography>
        </Box>
        <WrappedDataGrid
          columnDefinitions={columnDefs}
          endpoint={endpoints.locations.all}
          idField="Id"
        />
      </Container>
    </>
  );
};

export default Locations;
