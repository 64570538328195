import React from "react";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";

import { any } from "prop-types";

// MSAL imports
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

// Pages
import UnauthenticatedPage from "./pages/Unauthenticated";
import routes from "./utils/routes";
import DrawAssistant from "./ui-components/DrawAssistant";
import PageLayout from "./ui-components/PageLayout";

const propTypes = {
  pca: any /* find msal proptype*/,
};

const defaultProps = {
  pca: null,
};

function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <PageLayout>
        <AuthenticatedTemplate>
          <Pages />
          <DrawAssistant />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <UnauthenticatedPage />
        </UnauthenticatedTemplate>
      </PageLayout>
    </MsalProvider>
  );
}

function Pages() {
  return (
    <Switch>
      {routes.map(({ path, page: PageToRender, exact }) => (
        <Route path={path} key={path} exact={exact}>
          <PageToRender />
        </Route>
      ))}
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
