import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { Link } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";

const FixAlertActions = (param) => {
  let fixUrl;
  let fixText;
  let copyText;

  if (param.row.IsUnknownRetailer) {
    fixUrl = `/retailers?mode=filter&field=Name&value=${param.row.Retailer.charAt(
      0
    )}`;
    fixText = "Add Retailer";
    copyText = param.row.Retailer;
  } else if (param.row.IsUnknownStoreType) {
    fixUrl = `/retailers?mode=filter&field=Id&value=${param.row.RetailerId}`;
    fixText = "Add Store Type";
  } else if (param.row.IsUnknownClient) {
    fixUrl = `/clients?mode=filter&field=Name&value=${param.row.Client}`;
    fixText = "Add Client";
  }

  const handleCopy = () => {
    window.navigator.clipboard.writeText(copyText);
  };

  return (
    <>
      {fixUrl && fixText ? (
        <Button
          endIcon={<ChevronRight />}
          component={Link}
          to={fixUrl}
          onClick={handleCopy}
          color="primary"
        >
          {fixText}
        </Button>
      ) : (
        <Tooltip title="Incomplete data, review file">
          <WarningIcon color="error" />
        </Tooltip>
      )}
    </>
  );
};

export default FixAlertActions;
