import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Button,
  Box,
  useTheme,
  makeStyles,
  Link,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import WrappedDataGrid from "../ui-components/WrappedDataGrid";
import { endpoints } from "../api/_config";
import WrappedModal from "../ui-components/WrappedModal";
import Meta from "../ui-components/Meta";
import AddClient from "../ui-components/Clients/AddClient";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ClientActions from "../ui-components/DataGridCells/ClientActions";
import ToolTipCell from "../ui-components/DataGridCells/ToolTipCell";
import { useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
  },
}));

const Clients = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const [initialFilter, setInitialFilter] = useState();
  const [waitForInitialFilter, setWaitForInitialFilter] = useState(true);

  const { search } = useLocation();

  const params = new URLSearchParams(search);

  useEffect(() => {
    const mode = params.get("mode");
    const value = params.get("value");
    const field = params.get("field");
    switch (mode) {
      case "filter":
        setInitialFilter({
          items: [
            {
              columnField: field,
              operatorValue: "contains",
              value,
            },
          ],
        });
        break;
      default:
        setWaitForInitialFilter(false);
        break;
    }
  }, [search]);

  const handleClose = () => {
    setShowAdd(false);
    handleRefresh();
  };

  const handleRefresh = () => {
    setTriggerRefresh((prev) => !prev);
  };

  const clientColumnDefs = [
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      // eslint-disable-next-line react/display-name
      renderCell: (param) => (
        <Link href={param.row.Url} target="_blank">
          <Typography>
            {param.value} <OpenInNewIcon fontSize="small" />
          </Typography>
        </Link>
      ),
    },
    { field: "DaysAlertsValid", headerName: "Alert Expiry", flex: 1 },
    { field: "Enabled", headerName: "Enabled", flex: 1 },
    {
      field: "Aliases",
      // eslint-disable-next-line react/display-name
      renderCell: (param) => (
        <ToolTipCell value={param.value.map(({ Name }) => Name).join(", ")} />
      ),

      flex: 2,
      filterable: false,
      sortable: false,
    },

    {
      field: "edit",
      headerName: " ",
      width: 140,
      // eslint-disable-next-line react/display-name
      renderCell: (param) => (
        <ClientActions param={param} handleRefresh={handleRefresh} />
      ),
      filterable: false,
      sortable: false,
    },
  ];

  return (
    <>
      <Container maxWidth="lg">
        <Meta title="Clients" />
        <Box className={classes.sectionHeader}>
          <Typography variant="h2">Clients</Typography>
          <Button
            onClick={() => setShowAdd(true)}
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
          >
            Add Client
          </Button>
        </Box>

        <WrappedDataGrid
          columnDefinitions={clientColumnDefs}
          endpoint={endpoints.clients.all}
          idField="Id"
          triggeredRefresh={triggerRefresh}
          options={{ rowHeight: 120 }}
          showColumnToolbar
          showFilterToolbar
          initialFilter={initialFilter}
          waitForInitialFilter={waitForInitialFilter}
        />

        <WrappedModal open={showAdd} handleClose={handleClose}>
          <AddClient handleClose={handleClose} />
        </WrappedModal>
      </Container>
    </>
  );
};

export default Clients;
