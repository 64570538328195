import { AuditLogs } from "../pages/AuditLogs";
import AuthHeader from "../pages/AuthHeader";
import Clients from "../pages/Clients";
import DataProviders from "../pages/DataProviders";
import { DistributionAlerts } from "../pages/DistributionAlerts";
import { Home } from "../pages/Home";
import Locations from "../pages/Locations";
import Reports from "../pages/Reports";
import Retailers from "../pages/Retailers";
import ActionsRetailer from "../pages/actions/Retailer";
import { UploadAlerts } from "../pages/UploadAlerts";
import UploadAlertsDetails from "../pages/UploadAlertsDetails";
import ActionsClient from "../pages/actions/Client";
import ActionsStoreType from "../pages/actions/StoreType";
import QC from "../pages/QC";

const TodoPage = () => "TODO";

const routes = [
  {
    label: "Alerts",
    path: "/alerts",
    page: TodoPage,
  },
  {
    label: "Data Providers",
    path: "/data-providers",
    page: DataProviders,
  },
  {
    label: "Clients",
    path: "/clients",
    page: Clients,
  },
  {
    label: "Retailers",
    path: "/retailers",
    page: Retailers,
  },
  {
    label: "Locations",
    path: "/locations",
    page: Locations,
  },

  {
    label: "Rules",
    path: "/rules",
    page: TodoPage,
  },
  {
    label: "Job Creation Rules",
    path: "/job-creation-rules",
    page: TodoPage,
  },
  {
    label: "Audit Logs",
    path: "/audit-logs",
    page: AuditLogs,
  },
  {
    label: "Upload Alerts",
    path: "/upload-alerts",
    exact: true,
    page: UploadAlerts,
  },
  {
    label: "Upload Alerts",
    path: "/upload-alerts/:importRef",
    page: UploadAlertsDetails,
  },
  {
    label: "Distribution Alerts",
    path: "/distribution-alerts",
    page: DistributionAlerts,
  },
  {
    label: "Auth Headers",
    path: "/auth-headers",
    page: AuthHeader,
  },
  {
    label: "Reports",
    path: "/Reports",
    page: Reports,
  },
  {
    label: "Actions - Retailer",
    path: "/actions/retailer",
    page: ActionsRetailer,
  },
  {
    label: "Actions - Client",
    path: "/actions/client",
    page: ActionsClient,
  },
  {
    label: "Actions - Store Types",
    path: "/actions/storetype",
    page: ActionsStoreType,
  },
  { label: "QC", path: "/qc/:id", page: QC },
  {
    label: "Dashboard",
    path: "/",
    page: Home,
    inNav: false,
  }, // should always be last
];

export default routes;
