import {
  Box,
  Button,
  makeStyles,
  Snackbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";

import { apiCall } from "../../api/utils";
import { endpoints } from "../../api/_config";
import { Alert } from "@material-ui/lab";
import { func, string, shape } from "prop-types";
import { Add } from "@material-ui/icons";
import EditRow from "./EditRow";

const useStyle = makeStyles((theme) => ({
  editRow: {
    marginBottom: theme.spacing(1),
    borderBottom: "1px solid lightgrey",
  },
  aliasList: {
    maxHeight: "300px",
    overflowY: "auto",
  },
  addButtonWrap: {
    textAlign: "right",
    margin: `${theme.spacing(1)}px 0`,
  },
}));

const propTypes = {
  handleUpdate: func.isRequired,
  activeRetailer: shape({
    Name: string,
    Id: string,
    RetailerStoreType: string,
  }).isRequired,
};

const EditAliases = ({
  handleUpdate,
  activeRetailer: { Id, Name, RetailerAlias } = {},
}) => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const [error, setError] = useState(null);
  const [addAliasOpen, setAddAliasOpen] = useState(false);

  const saveEdit = async (name, id) => {
    try {
      const endpoint = endpoints.retailer.updateAlias.replace(":AliasId:", id);
      const { error } = await apiCall(endpoint, { Name: name });
      handleUpdate();
      if (error) setError(error.message);
    } catch (e) {
      console.error(e);
      setError(e.message);
    }
  };

  const saveNew = async (name, id) => {
    try {
      const endpoint = endpoints.retailer.addAlias.replace(":RetailerId:", id);
      const { error } = await apiCall(endpoint, { Name: name.trim() });
      setAddAliasOpen(false);
      handleUpdate();
      if (error) setError(error.message);
    } catch (e) {
      console.error(e);
      setError(e.message);
    }
  };

  return (
    <>
      <Typography variant="h4">{Name}</Typography>
      {addAliasOpen ? (
        <EditRow
          id={Id}
          onSave={saveNew}
          onCancel={() => setAddAliasOpen(false)}
          initialInput=""
          initialEdit
          fieldLabel="Add"
        />
      ) : (
        <Box className={classes.addButtonWrap}>
          <Button
            startIcon={<Add />}
            onClick={() => setAddAliasOpen(true)}
            color="primary"
            variant="contained"
          >
            Alias
          </Button>
        </Box>
      )}

      <Box className={classes.aliasList}>
        {RetailerAlias.map(({ Id: aliasId, Name: aliasName }) => (
          <EditRow
            key={aliasId}
            onSave={saveEdit}
            id={aliasId}
            initialInput={aliasName}
          />
        ))}

        <Snackbar open={error} onClose={() => setError(null)}>
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      </Box>
    </>
  );
};

EditAliases.propTypes = propTypes;

export default EditAliases;
