import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Snackbar,
  Typography,
  useTheme,
  Link as MaterialLink,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { useEffect, useState } from "react";
import { apiCall } from "../api/utils";
import WrappedFieldSet from "../ui-components/WrappedFieldSet";
import { endpoints } from "../api/_config";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";
import Meta from "../ui-components/Meta";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { ChevronRight } from "@material-ui/icons";

const useStyle = makeStyles({
  dataPoint: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    textAlign: "center",
    flexGrow: "1",
    backgroundColor: "#F4F4F4",
    padding: "8px",
    textDecoration: "none",
    color: "inherit",

    "& .title": { flexGrow: 1 },
    "& .figure": { fontSize: "48px" },
  },
});

export function Home() {
  const theme = useTheme();
  const classes = useStyle(theme);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [data, setData] = useState({
    Clients: null,
    DataProviders: null,
    FulfilledJobsPercent: null,
    Locations: null,
    NewClients: null,
    NewDataUploads: null,
    NewRetailers: null,
    NewStoreTypes: null,
    NumberOfOrdersPercent: null,
    Retailers: null,
    Rules: null,
    TotalAlerts: null,
    TotalValueOfOrders: null,
  });

  useEffect(async () => {
    try {
      setLoading(true);
      const { data, error: apiError } = await apiCall(endpoints.dashboard);
      setData(data?.Data ?? {});
      setError(apiError?.message);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }, [triggerRefresh]);

  const handleRefresh = () => {
    setTriggerRefresh((prev) => !prev);
  };
  // eslint-disable-next-line react/prop-types
  const DataPoint = ({ title, value = "-", href }) => {
    return (
      <Link to={href} className={classes.dataPoint}>
        <Typography className="title">{title}</Typography>

        {loading ? (
          <CircularProgress data-testid="loading" color="inherit" />
        ) : (
          <Typography className="figure" data-testid="loaded">
            {value}
          </Typography>
        )}
      </Link>
    );
  };

  const summaryData = [
    {
      heading: "Data Providers",
      value: data.DataProviders,
      href: "/data-providers",
    },
    { heading: "Retailers", value: data.Retailers, href: "/retailers" },
    { heading: "Locations", value: data.Locations, href: "/" },
    { heading: "Clients", value: data.Clients, href: "/clients" },
  ];

  const adminData = [
    {
      heading: "New retailers that need addressing",
      value: data.NewRetailers,
      href: "/actions/retailer",
    },
    {
      heading: "New store types",
      value: data.NewStoreTypes,
      href: "/actions/storetype",
    },
    {
      heading: "New clients",
      value: data.NewClients,
      href: "/actions/client",
    },
    {
      heading: "Data uploads requiring attention",
      value: data.NewDataUploads,
      href: "/upload-alerts",
    },
  ];

  return (
    <Container maxWidth="lg">
      <Meta title="Dashboard" description="RedAlerts stats page" />
      <Box display="flex" justifyContent="space-between">
        <Button
          color="secondary"
          endIcon={<RefreshIcon />}
          onClick={handleRefresh}
        >
          Refresh
        </Button>

        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/upload-alerts"
          endIcon={<ChevronRight />}
        >
          Get Started
        </Button>
      </Box>

      <WrappedFieldSet title="Summary">
        <Grid container spacing={3}>
          {summaryData.map(({ heading, value, href }) => (
            <Grid item xs key={heading} style={{ display: "flex" }}>
              <DataPoint title={heading} value={value} href={href} />
            </Grid>
          ))}
        </Grid>
      </WrappedFieldSet>
      <WrappedFieldSet title="Admin" color="primary">
        <Grid container spacing={3}>
          {adminData.map(({ heading, value, href }) => (
            <Grid item xs key={heading} style={{ display: "flex" }}>
              <DataPoint title={heading} value={value} href={href} />
            </Grid>
          ))}
        </Grid>
      </WrappedFieldSet>
      <WrappedFieldSet title="Reports">
        <Grid container>
          <Grid item xs={4}>
            <MaterialLink to="/reports" component={Link} color="inherit">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{
                  backgroundColor: "#f4f4f4",
                  minHeight: "112px",
                }}
              >
                <AssessmentIcon fontSize="large" />
                <Typography>Power BI Report</Typography>
              </Box>
            </MaterialLink>
          </Grid>
        </Grid>
      </WrappedFieldSet>
      <Snackbar color="error" open={error} onClose={() => setError(false)}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </Container>
  );
}
