import React from "react";
import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";

import WrappedDataGrid from "../../ui-components/WrappedDataGrid";
import { endpoints } from "../../api/_config";

import Meta from "../../ui-components/Meta";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
  },
}));

const ActionsClient = () => {
  const classes = useStyles();

  const FixActions = (param) => {
    return (
      <Button
        component={Link}
        to={`/clients?mode=filter&field=Name&value=${param.row.Client}`}
      >
        Fix
      </Button>
    );
  };

  const columnDefs = [
    { field: "Client", flex: 1 },
    { field: "Count", width: 150 },
    { field: "Fix", headerName: " ", renderCell: FixActions },
  ];

  return (
    <>
      <Container maxWidth="lg">
        <Meta title="Actions - Client" />
        <Box className={classes.sectionHeader}>
          <Typography variant="h2">Actions - Client</Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/clients"
          >
            Go To Clients
          </Button>
        </Box>
        <WrappedDataGrid
          columnDefinitions={columnDefs}
          endpoint={endpoints.data.getClientActions}
          idField="Client"
          pageSize={5}
        />
      </Container>
    </>
  );
};

export default ActionsClient;
