import {
  Button,
  Grid,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import { apiCall } from "../../api/utils";
import { endpoints } from "../../api/_config";
import { Alert } from "@material-ui/lab";
import { func, string, shape } from "prop-types";

const useStyle = makeStyles((theme) => ({
  textInput: {
    width: "100%",
  },
  inputWrap: {
    margin: `${theme.spacing(1)}px 0`,
  },
  submitButtonWrap: {
    textAlign: "right",
  },
}));

const propTypes = {
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
  activeRetailer: shape({ Name: string, Id: string }).isRequired,
};

const EditRetailerName = ({
  handleClose,
  handleUpdate,
  activeRetailer: { Name, Id } = {},
}) => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const [formInput, setFormInput] = useState({
    Name,
  });
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        Name: formInput.Name.trim(),
      };

      const endpoint = endpoints.retailer.update.replace(":Id:", Id);

      await apiCall(endpoint, body);
      handleUpdate();
      handleClose();
    } catch (e) {
      setError(e.message);
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name) {
      setFormInput({ ...formInput, [name]: value });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h2">Edit Name</Typography>
      <Grid
        className={classes.inputWrap}
        container
        direction="column"
        spacing={1}
      >
        <Grid item xs>
          <TextField
            className={classes.textInput}
            id="name"
            name="Name"
            label="Retailer"
            variant="outlined"
            value={formInput.Name}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs className={classes.submitButtonWrap}>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={error} onClose={() => setError(null)}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </form>
  );
};

EditRetailerName.propTypes = propTypes;

export default EditRetailerName;
