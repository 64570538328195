import React, { useState } from "react";

import EditAliases from "../Retailer/EditAliases";
import EditStoreTypes from "../Retailer/EditStoreType";
import { ChevronRight } from "@material-ui/icons";
import EditRetailerName from "../Retailer/EditRetailerName";
import { Box, Button, makeStyles, useTheme } from "@material-ui/core";
import WrappedModal from "../WrappedModal";
import { shape, func } from "prop-types";

const useStyles = makeStyles(() => ({
  actionBox: {
    display: "flex",
    flexDirection: "column",

    "& Button": {
      textTransform: "capitalize",
      paddingLeft: 0,
      paddingBottom: 0,
      justifyContent: "flex-start",
    },
  },
}));

const propTypes = {
  param: shape({}).isRequired,
  handleRefresh: func,
};

const RetailerActions = ({ param, handleRefresh }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [editNameOpen, setEditNameOpen] = useState(false);
  const [editAliasOpen, setEditAliasOpen] = useState(false);
  const [editStoreTypeOpen, setEditStoreTypeOpen] = useState(false);

  const handleClose = () => {
    setEditNameOpen(false);
    setEditStoreTypeOpen(false);
    setEditAliasOpen(false);
  };
  return (
    <Box className={classes.actionBox}>
      <Button
        color="primary"
        endIcon={<ChevronRight />}
        onClick={() => setEditNameOpen(true)}
      >
        Edit Name
      </Button>
      <Button
        color="primary"
        endIcon={<ChevronRight />}
        onClick={() => setEditAliasOpen(true)}
      >
        Edit Aliases
      </Button>
      <Button
        color="primary"
        endIcon={<ChevronRight />}
        onClick={() => setEditStoreTypeOpen(true)}
      >
        Edit Types
      </Button>

      <WrappedModal
        name="edit-name"
        open={editNameOpen}
        handleClose={handleClose}
      >
        <EditRetailerName
          handleClose={handleClose}
          handleUpdate={handleRefresh}
          activeRetailer={param.row}
        />
      </WrappedModal>

      <WrappedModal
        name="edit-alias"
        open={editAliasOpen}
        handleClose={handleClose}
      >
        <EditAliases
          activeRetailer={param.row}
          handleClose={handleClose}
          handleUpdate={handleRefresh}
        />
      </WrappedModal>

      <WrappedModal
        name="edit-store-types"
        open={editStoreTypeOpen}
        handleClose={handleClose}
      >
        <EditStoreTypes
          activeRetailer={param.row}
          handleClose={handleClose}
          handleUpdate={handleRefresh}
        />
      </WrappedModal>
    </Box>
  );
};

RetailerActions.propTypes = propTypes;

export default RetailerActions;
