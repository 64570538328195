import { LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { shape, string, number } from "prop-types";
import React from "react";
import { useHistory } from "react-router";
import WrappedChip from "../WrappedChip";

const useStyle = makeStyles(() => ({
  error: {
    whiteSpace: "pre-line",
    overflowY: "auto",
    maxHeight: "140px",
  },
  pending: {
    display: "flex",
    flexDirection: "column",
  },
}));

const propTypes = {
  row: shape({
    FailedAlerts: number,
    TotalAlerts: number,
    FilteredAlerts: number,
    ImportRef: string,
    FileStatus: string,
    StatusMessage: string,
  }).isRequired,
};

const defaultProps = {
  row: {},
};

const AlertSummary = ({
  row: {
    FailedAlerts: failed,
    ValidAlerts: successful,
    FilteredAlerts: filtered,
    ImportRef: importRef,
    FileStatus: fileStatus,
    Status: importStatus,
    StatusMessage: message,
  } = {},
}) => {
  const history = useHistory();
  const classes = useStyle();

  return fileStatus === "Error" ? (
    <Typography className={`alert-summary__error ${classes.error}`}>
      {message}
    </Typography>
  ) : (
    <>
      {["Awaiting Reprocessing", "Pending", "Awaiting Completion"].includes(
        importStatus
      ) ? (
        <div className={classes.pending}>
          <Typography>{importStatus}</Typography>
          <LinearProgress />
        </div>
      ) : (
        <div className="alert-summary__chips">
          <WrappedChip
            label="Successful"
            number={successful}
            onClick={() =>
              history.push(`/upload-alerts/${importRef}?view=valid`)
            }
            color={successful > 0 ? "success" : "grey"}
          />
          <WrappedChip
            label="Failed"
            number={failed}
            onClick={() =>
              history.push(`/upload-alerts/${importRef}?view=invalid`)
            }
            color={failed > 0 ? "primary" : "grey"}
          />
          <WrappedChip
            label="Filtered"
            number={filtered}
            onClick={() =>
              history.push(`/upload-alerts/${importRef}?view=filtered`)
            }
            color={filtered > 0 ? "secondary" : "grey"}
          />
        </div>
      )}
    </>
  );
};
AlertSummary.defaultProps = defaultProps;
AlertSummary.propTypes = propTypes;
export default AlertSummary;
