import {
  Box,
  Button,
  Drawer,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ChevronRight } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectDrawAssistant, disable } from "../../store/drawerAssistant";

const useStyle = makeStyles((theme) => ({
  root: {
    "& .MuiDrawer-paperAnchorBottom": {
      maxWidth: "80%",
      margin: "0 auto",
      border: "1px solid",
      borderColor: "black",
      borderTopRightRadius: "15px",
      borderTopLeftRadius: "15px",
      backgroundColor: "#f4f4f4",
      borderBottom: "0",
      padding: theme.spacing(1),
      overflow: "visible",
    },
  },
  contentWrap: {
    minHeight: "200px",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },

  closeWrap: {
    position: "absolute",
    left: "50%",
    top: "-40px",
    transform: "translateX(-50%)",
    backgroundColor: "#f4f4f4",
    border: "1px solid black",
    borderBottom: "0",
    borderTopLeftRadius: "28px",
    borderTopRightRadius: "28px",
    visibility: "visible",

    "& svg": {
      width: "2em",
    },
  },

  actionWrap: {
    margin: theme.spacing(1),
    backgroundColor: "white",
    borderRadius: "5px",

    "& .title": {
      fontSize: "18px",
      fontWeight: 400,
      marginBottom: "20px",
    },

    "& .action": {
      fontSize: "18px",
      fontWeight: 500,
      marginBottom: "20px",
    },
  },
}));

const DrawAssistant = () => {
  const { enabled, suggestions, info, buttons } = useSelector(
    selectDrawAssistant
  );
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const classes = useStyle(theme);

  const dispatch = useDispatch();

  return (
    enabled && (
      <Drawer
        open={open}
        anchor="bottom"
        variant="persistent"
        elevation="32"
        className={classes.root}
      >
        <div className={classes.contentWrap}>
          <div className={classes.closeWrap}>
            <div onClick={() => setOpen((prev) => !prev)}>
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </div>
          </div>
          <Grid container spacing={2}>
            {suggestions.map(({ title, action, explanation }) => (
              <Grid item xs key={title} className={classes.actionWrap}>
                <Typography className="title">{title}</Typography>
                <Typography className="action">{action}</Typography>
                <Typography className="explanation">{explanation}</Typography>
              </Grid>
            ))}

            <Grid item xs className={classes.actionWrap}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-evenly"
                height="100%"
              >
                {info.map(([title, value], index) => (
                  <Typography key={index}>
                    <strong>{title}</strong> - {value}
                  </Typography>
                ))}
                {buttons.map(({ text, to }, index) => (
                  <Button
                    key={index}
                    component={Link}
                    to={to}
                    color="secondary"
                    endIcon={<ChevronRight />}
                  >
                    {text}
                  </Button>
                ))}
                <Button onClick={() => dispatch(disable())}>Done</Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    )
  );
};

export default DrawAssistant;
