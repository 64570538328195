import React from "react";
import { AppBar, Toolbar, makeStyles, Link, MenuItem } from "@material-ui/core";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { Link as RouterLink } from "react-router-dom";

import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "../AuthHelpers/SignInSignOutButton";
import Logo from "../../images/red-alerts-logo.png";

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#FFF",
    paddingRight: "7px",
    paddingLeft: "7px",
    "@media (max-width: 1200px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    width: "140px",
  },
  menuButton: {
    fontFamily: '"Montserrat Alternates",sans-serif',
    fontWeight: 700,
    size: "16px",
    marginLeft: "24px",
    color: "#251010",
    textTransform: "capitalize",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
  loggedIn: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function Header() {
  const { header, logo, toolbar, loggedIn, menuButton } = useStyles();

  return (
    <AppBar className={header}>
      <Toolbar className={toolbar}>
        <RouterLink to="/" data-testid="header-logo">
          <img src={Logo} className={logo} />
        </RouterLink>

        <AuthenticatedTemplate>
          <Link
            component={RouterLink}
            to="/"
            color="inherit"
            style={{ textDecoration: "none" }}
            data-testid="header-link"
          >
            <MenuItem className={menuButton}>Dashboard</MenuItem>
          </Link>
        </AuthenticatedTemplate>

        <div className={loggedIn} data-testid="header-welcome-name">
          <WelcomeName />
          <SignInSignOutButton />
        </div>
      </Toolbar>
    </AppBar>
  );
}
