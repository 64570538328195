import { createMuiTheme } from "@material-ui/core/styles";

// Create a theme instance.
export const globalTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#F80B0B",
    },
    secondary: {
      main: "#89016F",
    },
    success: {
      main: "#4DDB4A",
    },
    grey: {
      main: "#bbbbbb",
      dark: "#a4a4a4",
      contrastText: "#fff",
    },
    background: {
      default: "#fff",
    },
  },

  typography: {
    fontSize: 16,
    fontFamily: '"Montserrat",sans-serif',
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "1rem",
    },
    h1: {
      fontSize: "2rem",
      fontFamily: '"Montserrat Alternates",sans-serif',
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.75rem",
      fontFamily: '"Montserrat Alternates",sans-serif',
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.5rem",
      fontFamily: '"Montserrat Alternates",sans-serif',
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.25rem",
      fontFamily: '"Montserrat Alternates",sans-serif',
      fontWeight: 500,
    },
  },
});
