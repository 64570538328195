import { Button, Tab, Typography } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import WrappedDataGrid from "../ui-components/WrappedDataGrid";
import AlertDetails from "../ui-components/DataGridCells/AlertDetails";
import FixAlertActions from "../ui-components/DataGridCells/FixAlertActions";
import { formatServerDate } from "../utils/formatters";
import { endpoints } from "../api/_config";
import ToolTipCell from "../ui-components/DataGridCells/ToolTipCell";
import Meta from "../ui-components/Meta";

const UploadAlertsDetails = () => {
  const { search } = useLocation();
  const history = useHistory();
  const { importRef } = useParams();

  const [currentTab, setCurrentTab] = React.useState("valid");
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    const searchParam = new URLSearchParams(search);
    const view = searchParam.get("view");
    if (view) {
      setCurrentTab(searchParam.get("view"));
    }
  }, [search]);

  const invalidColumnDefinitions = [
    {
      field: "details",
      headerName: " ",
      renderCell: AlertDetails,
      width: 60,
      sortable: false,
      filterable: false,
    },
    { field: "AlertType", headerName: "Type", flex: 1 },
    { field: "Client", flex: 1 },
    { field: "Retailer", flex: 1 },
    { field: "StoreNumber", headerName: "Store", width: 100 },
    { field: "StorePostcode", headerName: "Postcode", width: 130 },
    {
      field: "Sku",
      renderCell: ToolTipCell,
      width: 150,
    },
    {
      field: "Product",
      renderCell: ToolTipCell,
      width: 200,
    },
    {
      field: "AlertValue",
      headerName: "Value",
      width: 130,
      valueFormatter: ({ value }) => `£${parseFloat(value).toFixed(2)}`,
    },
    {
      field: "ValidationMessage",
      description: "ValidationMessage",
      headerName: "Message",
      flex: 2,
      renderCell: ToolTipCell,
    },
    {
      field: "action",
      headerName: "Action",
      // eslint-disable-next-line react/display-name
      renderCell: FixAlertActions,
      width: 200,
      sortable: false,
      filterable: false,
    },
  ];

  const validColumnDefinitions = [
    { field: "details", headerName: " ", renderCell: AlertDetails, width: 60 },

    { field: "AlertType", headerName: "Type", flex: 1 },
    { field: "Client", flex: 1 },
    { field: "Retailer", flex: 1 },
    { field: "StoreNumber", headerName: "Store", flex: 1 },
    { field: "StorePostcode", headerName: "Postcode", flex: 1 },
    {
      field: "Sku",
      renderCell: ToolTipCell,
      flex: 1,
    },
    {
      field: "Product",
      renderCell: ToolTipCell,
      flex: 1,
    },
    {
      field: "AlertValue",
      headerName: "Value",
      width: 100,
      valueFormatter: ({ value }) => `£${parseFloat(value).toFixed(2)}`,
    },
  ];
  const filteredColumnDefinitions = [
    { field: "details", headerName: " ", renderCell: AlertDetails, width: 60 },

    { field: "AlertType", headerName: "Type", flex: 1 },
    { field: "Client", flex: 1 },
    { field: "Retailer", flex: 1 },
    { field: "StoreNumber", headerName: "Store", width: 100 },
    { field: "StorePostcode", headerName: "Postcode", width: 130 },
    {
      field: "Sku",
      renderCell: ToolTipCell,
      width: 150,
    },
    {
      field: "Product",
      renderCell: ToolTipCell,
      width: 200,
    },
    {
      field: "AlertValue",
      headerName: "Value",
      width: 100,
      valueFormatter: ({ value }) => `£${parseFloat(value).toFixed(2)}`,
    },
    {
      field: "ScopeMessage",
      headerName: "Reason",
      renderCell: ToolTipCell,
      flex: 2,
    },
  ];

  const auditLogColumnDefinitions = [
    {
      field: "Date",
      valueFormatter: (params) => formatServerDate(params.value),
      width: 220,
    },
    { field: "Message", renderCell: ToolTipCell, flex: 1 },
  ];
  return (
    <>
      <Meta title="Alert Details" />
      <Typography variant="h1" data-testid="heading">
        All Alerts
      </Typography>
      <Button
        data-testid="heading-cta"
        startIcon={<ArrowBackIcon />}
        onClick={() => history.push("/upload-alerts")}
      >
        Back
      </Button>
      <TabContext value={currentTab}>
        <TabList onChange={handleTabChange}>
          <Tab label="Valid" value="valid" data-testid="tab-valid"></Tab>
          <Tab label="Invalid" value="invalid" data-testid="tab-invalid"></Tab>
          <Tab
            label="Filtered"
            value="filtered"
            data-testid="tab-filtered"
          ></Tab>
          <Tab label="Log" value="log" data-testid="tab-log"></Tab>
        </TabList>
        <TabPanel value="valid" data-testid="panel-valid">
          <WrappedDataGrid
            endpoint={endpoints.upload.valid.replace(/:ImportRef:/, importRef)}
            columnDefinitions={validColumnDefinitions}
            idField="Id"
            pageSize={10}
            options={{
              getRowClassName: (param) => {
                if (!param.row.IsInScope) {
                  return "row--filtered";
                }
              },
            }}
          />
        </TabPanel>
        <TabPanel value="invalid" data-testid="panel-invalid">
          <WrappedDataGrid
            endpoint={endpoints.upload.invalid.replace(
              /:ImportRef:/,
              importRef
            )}
            columnDefinitions={invalidColumnDefinitions}
            idField="Id"
            pageSize={10}
          />
        </TabPanel>
        <TabPanel value="filtered" data-testid="panel-filtered">
          <WrappedDataGrid
            endpoint={endpoints.upload.filtered.replace(
              /:ImportRef:/,
              importRef
            )}
            columnDefinitions={filteredColumnDefinitions}
            idField="Id"
            pageSize={10}
          />
        </TabPanel>
        <TabPanel value="log" data-testid="panel-log">
          <WrappedDataGrid
            endpoint={endpoints.upload.auditLogs.replace(
              /:ImportRef:/,
              importRef
            )}
            columnDefinitions={auditLogColumnDefinitions}
            idField="id"
            pageSize={15}
          />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default UploadAlertsDetails;
