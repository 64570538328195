import React, { useState } from "react";
import { shape, arrayOf, string } from "prop-types";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";

const propTypes = {
  data: shape({}).isRequired,
  headers: arrayOf(string),
};

const defaultProps = {
  headers: ["Key", "Value"],
  data: {},
};

const WrappedTableJSON = ({ data, headers }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rows = Object.keys(data);

  const currentRows =
    rowsPerPage > 0
      ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : rows;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer className="wrapped-table-json" component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={`${index}-${header}`}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentRows.map((key) => (
            <TableRow key={key} className="wrapped-table-json__body-row">
              <TableCell>{key}</TableCell>
              <TableCell>{data[key]?.toString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
WrappedTableJSON.propTypes = propTypes;
WrappedTableJSON.defaultProps = defaultProps;
export default WrappedTableJSON;
