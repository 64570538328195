import React, { useState } from "react";
import {
  Box,
  Button,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { shape, func, string } from "prop-types";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useHistory } from "react-router";

import { apiCall } from "../../api/utils";
import { endpoints } from "../../api/_config";
import WrappedDialog from "../WrappedDialog";

const useStyle = makeStyles({
  actionBox: {
    display: "flex",
    flexDirection: "column",

    "& Button": {
      textTransform: "capitalize",
      paddingLeft: 0,
      paddingBottom: 0,
      justifyContent: "flex-start",
    },
  },
});

const defaultProps = {
  row: {},
  handleTriggeredRefresh: (r) => r,
  setError: (r) => r,
  setPendingAutoRefreshEnabled: (r) => r,
};

const propTypes = {
  params: shape({
    row: shape({ Status: string, ImportRef: string, FileStatus: string }),
  }),
  handleTriggeredRefresh: func,
  setError: func,
  setPendingAutoRefreshEnabled: func,
  setHistoryAutoRefreshEnabled: func,
};

const Actions = ({
  params,
  handleTriggeredRefresh,
  setError,
  setPendingAutoRefreshEnabled,
  setHistoryAutoRefreshEnabled,
}) => {
  const {
    row: {
      Status: status,
      ImportRef: importRef,
      FileStatus,
      ValidAlerts,
      FilteredAlerts,
    },
  } = params;

  const theme = useTheme();
  const classes = useStyle(theme);
  const history = useHistory();

  const [showRefreshDialog, setShowRefreshDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showProceedDialog, setShowProceedDialog] = useState(false);

  const handleComplete = async (importRef) => {
    const endpoint = endpoints.upload.markComplete.replace(
      /:ImportRef:/,
      importRef
    );

    const { error } = await apiCall(endpoint);
    handleTriggeredRefresh?.();
    setHistoryAutoRefreshEnabled?.(true);

    if (error) {
      console.error(error);
      setError(`Complete - ${error.message}`);
    }
  };

  const handleCancel = async (importRef) => {
    const endpoint = endpoints.upload.markCancelled.replace(
      /:ImportRef:/,
      importRef
    );

    const { error } = await apiCall(endpoint);
    handleTriggeredRefresh?.();
    if (error) {
      console.error(error);
      setError(`Cancel - ${error.message}`);
    }
  };

  const handleRefresh = async (importRef) => {
    setShowRefreshDialog(false);
    const endpoint = endpoints.upload.refresh.replace(/:ImportRef:/, importRef);
    const { error } = await apiCall(endpoint);
    handleTriggeredRefresh?.();
    setPendingAutoRefreshEnabled?.(true);
    if (error) {
      console.error(error);
      setError(`Refresh - ${error.message}`);
    }
  };

  const CancelAction = () => (
    <>
      <Button
        className="action__cancel"
        endIcon={<ChevronRightIcon />}
        onClick={() => setShowCancelDialog(true)}
      >
        Cancel
      </Button>
      <WrappedDialog
        title="Cancel?"
        open={showCancelDialog}
        onCancel={() => setShowCancelDialog(false)}
        onClose={() => setShowCancelDialog(false)}
        onProceed={() => handleCancel(importRef)}
      >
        <Typography>
          The result will be that the alerts will not be shared with the brand
          owner. This action cannot be undone (you would need to re-import the
          data if you wish to create alerts from this file at a later time).
        </Typography>
      </WrappedDialog>
    </>
  );

  const RefreshAction = () => (
    <>
      <Button
        className="action__refresh"
        endIcon={<RefreshIcon />}
        onClick={() => setShowRefreshDialog(true)}
      >
        Refresh Upload
      </Button>
      <WrappedDialog
        title="Refresh?"
        open={showRefreshDialog}
        onCancel={() => setShowRefreshDialog(false)}
        onClose={() => setShowRefreshDialog(false)}
        onProceed={() => handleRefresh(importRef)}
      >
        <Typography>
          Use this feature after you have resolved some or all of the issues
          with the alerts (e.g. creating a new client alias configuration
          setting).
          <br /> <br /> The imported file will be re-ran through the import
          process and will then show you an updated file status having used the
          new configuration settings you have added. Whilst the file is
          refreshing, it will be removed from the ‘pending data uploads’ list
          and will re-appear once the import is complete.
        </Typography>
      </WrappedDialog>
    </>
  );

  const ProceedAction = () => (
    <>
      <Button
        className="action__proceed"
        color="secondary"
        endIcon={<ChevronRightIcon color="secondary" />}
        onClick={() => setShowProceedDialog(true)}
      >
        Submit successful alerts
      </Button>
      <WrappedDialog
        title="Proceed?"
        open={showProceedDialog}
        onCancel={() => setShowProceedDialog(false)}
        onClose={() => setShowProceedDialog(false)}
        onProceed={() => handleComplete(importRef)}
      >
        <Typography>
          {`The successfully imported alerts (of which there are ${
            ValidAlerts - FilteredAlerts
          }) will be made
          immediately available to the relevant clients for them to review and
          approve which ones they want us to address.`}
          <br /> <br />
          Any alerts that have failed in the import process or that have been
          filtered out, will not be made avaialble to the client.
        </Typography>
      </WrappedDialog>
    </>
  );

  const ReviewAction = () => (
    <Button
      className="action__review"
      color="secondary"
      endIcon={<ChevronRightIcon color="secondary" />}
      onClick={() => history.push(`/upload-alerts/${importRef}?view=invalid`)}
    >
      Review Alerts
    </Button>
  );

  const LogsButton = () => (
    <Button
      className="action__logs"
      color="secondary"
      endIcon={<ChevronRightIcon color="secondary" />}
      onClick={() => history.push(`/upload-alerts/${importRef}?view=log`)}
    >
      Review Logs
    </Button>
  );

  const SeeDetailsButton = () => (
    <Button
      className="action__details"
      color="secondary"
      endIcon={<ChevronRightIcon color="secondary" />}
      onClick={() => history.push(`/upload-alerts/${importRef}`)}
    >
      View Details
    </Button>
  );

  const AlertActions = () => (
    <>
      {status === "Good to go" && (
        <>
          <ProceedAction />
          <CancelAction />
        </>
      )}
      {["Awaiting Reprocessing", "Pending", "Awaiting Completion"].includes(
        status
      ) && <CancelAction />}
      {status === "Attention Required" && (
        <>
          <RefreshAction />
          <ReviewAction />
          {ValidAlerts - FilteredAlerts > 0 && <ProceedAction />}
          <CancelAction />
        </>
      )}
      {["Cancelled", "Complete"].includes(status) && <SeeDetailsButton />}
    </>
  );

  const FileActions = () => (
    <>
      <LogsButton />
      <CancelAction />
    </>
  );

  return (
    <Box className={classes.actionBox}>
      {FileStatus === "Error" ? <FileActions /> : <AlertActions />}
    </Box>
  );
};
Actions.propTypes = propTypes;
Actions.defaultProps = defaultProps;
export default Actions;
