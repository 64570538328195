import {
  Box,
  Container,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import React from "react";
import Meta from "../ui-components/Meta";

const useStyle = makeStyles((theme) => ({
  iframeWrap: {
    position: "relative",
    overflow: "hidden",
    paddingTop: "56.25%", // 16 x 9
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0,
  },
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
  },
}));

const Reports = () => {
  const theme = useTheme();
  const classes = useStyle(theme);
  return (
    <Container maxWidth="lg">
      <Meta title="Reports" />
      <Box className={classes.sectionHeader}>
        <Typography variant="h2">Report</Typography>
      </Box>
      <div className={classes.iframeWrap}>
        <iframe
          className={classes.iframe}
          src="https://app.powerbi.com/reportEmbed?reportId=a7fdcbc8-5f41-48fc-b86b-21e623c029a5&autoAuth=true&ctid=5a316040-6cf6-43b6-b5bc-e9f10883356d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVrLXNvdXRoLWItcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D%22&filterPaneEnabled=false&navContentPaneEnabled=false"
          frameBorder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </Container>
  );
};

export default Reports;
