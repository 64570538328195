import { Button, Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getDefaultHeaders } from "../api/utils";
import Meta from "../ui-components/Meta";
import { useDispatch } from "react-redux";
import { enable, setContent } from "../store/drawerAssistant";
import { useHistory } from "react-router";

const AuthHeader = () => {
  const [authHeader, setAuthHeader] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(async () => {
    const headers = await getDefaultHeaders();
    setAuthHeader(headers.Authorization);
  }, []);

  const handleAssistDrawer = () => {
    history.push("/retailers");
    dispatch(
      setContent({
        suggestions: [
          {
            title: "Is Waitrose already listed under a similar retailer?",
            action: "Add an alias",
            explanation:
              "To add an alias, edit the alias of the retailer and enter the name, then click the save button.",
          },
          {
            title: "Waitrose not present?",
            action: "Add a retailer",
            explanation:
              "Select the add retailer button and will out the information.",
          },
        ],
        info: [["Retailer", "Waitrose"]],
        buttons: [{ text: "Back to Issues", to: "/actions/retailer" }],
      })
    );
    dispatch(enable());
  };

  return (
    <Container maxWidth="md">
      <Meta title="Authentication Headers" />
      <p style={{ overflowWrap: "anywhere" }}>{authHeader}</p>

      <Button onClick={() => handleAssistDrawer()}>
        Show Assistant Drawer
      </Button>
    </Container>
  );
};

export default AuthHeader;
