import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
  useTheme,
  Switch,
  OutlinedInput,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { apiCall } from "../../api/utils";
import { endpoints } from "../../api/_config";
import { Alert, Autocomplete } from "@material-ui/lab";
import { func, string } from "prop-types";
import HelpIcon from "@material-ui/icons/Help";
import WrappedDialog from "../WrappedDialog";

const useStyle = makeStyles((theme) => ({
  textInput: {
    width: "100%",
  },
  inputWrap: {
    margin: `${theme.spacing(1)}px 0`,
  },
  submitButtonWrap: {
    textAlign: "right",
  },
}));

const propTypes = {
  handleClose: func.isRequired,
  name: string,
};

const defaultProps = {
  name: "",
};

const AddClient = ({ handleClose }) => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const [organisations, setOrganisations] = useState([]);
  const [organisationUnits, setOrganisationUnits] = useState([]);
  const [formInput, setFormInput] = useState({
    Name: "",
    OrganisationId: null,
    OrganisationUnitId: "",
    DaysAlertsValid: "",
    Enabled: true,
  });
  const [error, setError] = useState(null);
  const [showDaysDialog, setShowDaysDialog] = useState(false);

  useEffect(async () => {
    try {
      if (formInput.OrganisationId) {
        const endpoint = endpoints.clients.getOrgsUnits.replace(
          ":OrgId:",
          formInput.OrganisationId
        );
        const { data, error } = await apiCall(endpoint);
        setOrganisationUnits(data.Data || []);
        if (error) {
          setError(error.message);
        }
      }
    } catch (e) {
      console.error(e);
      setError(e.message);
    }
  }, [formInput.OrganisationId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const endpoint = endpoints.clients.addClient.replace(
        ":OrgUnitId:",
        formInput.OrganisationUnitId
      );
      const body = {
        Name: formInput.Name,
        Enabled: formInput.Enabled,
        DaysAlertsValid: formInput.DaysAlertsValid,
      };

      const { error } = await apiCall(endpoint, body);
      if (error) {
        console.error(error);
        setError(error.message);
      } else {
        handleClose();
      }
    } catch (e) {
      setError(e.message);
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name) {
      setFormInput({ ...formInput, [name]: value });
    }
  };

  const handleOrgIdChange = (e, value) => {
    try {
      setFormInput({
        ...formInput,
        OrganisationId: value?.Id ?? "",
      });
    } catch (e) {
      setError(e.message);
    }
  };

  const handleOrgUnitIdChange = (e, value) => {
    try {
      setFormInput({
        ...formInput,
        OrganisationUnitId: value?.Id ?? "",
      });
    } catch (e) {
      setError(e.message);
    }
  };

  const handleCheckedChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    if (name) {
      setFormInput({ ...formInput, [name]: checked });
    }
  };

  const handleOrgInputChange = async (e) => {
    try {
      if (e.target.value.length >= 3) {
        const { data, error } = await apiCall(
          endpoints.clients.allOrganisations,
          {
            pageInfo: { page: 1, pageSize: 200 },
            filterInfo: {
              items: [
                {
                  columnField: "Name",
                  operatorValue: "contains",
                  value: e.target.value,
                },
              ],
              linkOperator: "and",
            },
          }
        );
        setOrganisations(data.Data);
        if (error) {
          setError(error.message);
        }
      } else {
        setOrganisations([]);
      }
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h2">Add Client</Typography>
      <Grid
        className={classes.inputWrap}
        container
        direction="column"
        spacing={1}
      >
        <Grid item xs>
          <TextField
            className={classes.textInput}
            id="name"
            name="Name"
            label="Client"
            variant="outlined"
            value={formInput.Name}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs>
          <Autocomplete
            id="organisation-id"
            options={organisations}
            getOptionLabel={(option) => option.Name}
            loading={organisations.length === 0}
            loadingText="Type to search..."
            onChange={handleOrgIdChange}
            onInputChange={handleOrgInputChange}
            selectOnFocus
            clearOnBlur
            renderInput={(params) => (
              <TextField
                {...params}
                name="OrganisationId"
                label="Organisation"
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <Autocomplete
            id="organisation-unit-id"
            options={organisationUnits}
            getOptionLabel={(option) => option.Name}
            onChange={handleOrgUnitIdChange}
            selectOnFocus
            clearOnBlur
            renderInput={(params) => (
              <TextField
                {...params}
                name="OrganisationUnitId"
                label="Organisation Unit"
                variant="outlined"
                required
              />
            )}
          />
        </Grid>

        <Grid item xs>
          <FormControl fullWidth variant="outlined">
            <InputLabel required>Alert Expiry</InputLabel>
            <OutlinedInput
              className={classes.textInput}
              id="days"
              name="DaysAlertsValid"
              value={formInput.DaysAlertsValid}
              onChange={handleInputChange}
              type="number"
              inputProps={{ min: 1, max: 365, step: 1 }}
              placeholder="1 to 365 days"
              required
              label="Alert Expiry"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowDaysDialog(true)}>
                    <HelpIcon />
                  </IconButton>
                  <WrappedDialog
                    open={showDaysDialog}
                    onClose={() => setShowDaysDialog(false)}
                    onCancel={() => setShowDaysDialog(false)}
                    cancelText="Okay"
                  >
                    <Typography>
                      After this many number of days, any alerts that have not
                      been approved by the client for us to complete will be
                      automatically hidden from view in readiness for the next
                      set of alerts to come through. <br />
                      <br /> In selecting this number, you should consider the
                      frequency of the data received for all retailers for this
                      client
                    </Typography>
                  </WrappedDialog>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={formInput.Enabled}
                  onChange={handleCheckedChange}
                  name="Enabled"
                  color="primary"
                />
              }
              label="Enabled"
            />
          </FormControl>
        </Grid>

        <Grid item xs className={classes.submitButtonWrap}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add Client
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={error} onClose={() => setError(null)}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </form>
  );
};

AddClient.propTypes = propTypes;
AddClient.defaultProps = defaultProps;

export default AddClient;
