import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Snackbar,
  Typography,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import FileUpload from "../ui-components/FileUpload";
import WrappedModal from "../ui-components/WrappedModal";
import AddIcon from "@material-ui/icons/Add";

import WrappedDataGrid from "../ui-components/WrappedDataGrid";
import { Alert } from "@material-ui/lab";
import UploadSummary from "../ui-components/DataGridCells/UploadSummary";
import AlertSummary from "../ui-components/DataGridCells/AlertSummary";
import Actions from "../ui-components/DataGridCells/Actions";
import { endpoints } from "../api/_config";
import Meta from "../ui-components/Meta";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
  },
  section: {
    paddingBottom: theme.spacing(2),
  },
}));

export function UploadAlerts() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [triggeredRefresh, setTriggeredRefresh] = useState(false);
  const [historyAutoRefreshEnabled, setHistoryAutoRefreshEnabled] = useState(
    false
  );
  const [pendingAutoRefreshEnabled, setPendingAutoRefreshEnabled] = useState(
    false
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);

  const rowHeight = 140;

  const handleTriggeredRefresh = () => {
    setTriggeredRefresh((prev) => !prev);
  };

  const handleFileUpload = () => {
    handleTriggeredRefresh();
    setPendingAutoRefreshEnabled(true);
    setModalOpen(false);
  };

  const uploadColumnDefs = [
    {
      field: "CreatedDate",
      headerName: "Upload",
      renderCell: UploadSummary,
      width: 250,
      valueFormatter: (params) => new Date(params.value),
      sortComparator: (v1, v2) => v1 - v2,
    },
    // {
    //   field: "Status",
    //   renderCell: StatusTooltip,
    //   width: 185,
    // },
    {
      field: "Alerts",
      renderCell: AlertSummary,
      width: 450,
      sortable: false,
      filterable: false,
    },
    {
      field: "Action",
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <Actions
          params={params}
          handleTriggeredRefresh={handleTriggeredRefresh}
          setError={setError}
          setPendingAutoRefreshEnabled={setPendingAutoRefreshEnabled}
          setHistoryAutoRefreshEnabled={setHistoryAutoRefreshEnabled}
        />
      ),
      flex: 1,
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <Container maxWidth="lg">
      <Meta title="Upload Alerts" />
      <section className={classes.section}>
        <Box className={classes.sectionHeader}>
          <Typography variant="h2">Pending Data Uploads</Typography>

          <Button
            aria-labelledby="file-upload-modal-button"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setModalOpen(true)}
          >
            New data upload
          </Button>
        </Box>
        <WrappedDataGrid
          endpoint={endpoints.upload.pending}
          columnDefinitions={uploadColumnDefs}
          idField="ImportRef"
          showAutoRefresh
          setAutoRefreshEnabled={setPendingAutoRefreshEnabled}
          autoRefreshEnabled={pendingAutoRefreshEnabled}
          triggeredRefresh={triggeredRefresh}
          options={{ rowHeight }}
          pageSize={3}
        />
      </section>

      <section className={classes.section}>
        <Box className={classes.sectionHeader}>
          <Typography variant="h2">Data Upload History</Typography>
        </Box>
        <WrappedDataGrid
          endpoint={endpoints.upload.history}
          columnDefinitions={uploadColumnDefs}
          idField="ImportRef"
          showAutoRefresh
          setAutoRefreshEnabled={setHistoryAutoRefreshEnabled}
          autoRefreshEnabled={historyAutoRefreshEnabled}
          triggeredRefresh={triggeredRefresh}
          options={{ rowHeight }}
          pageSize={3}
        />
      </section>

      <WrappedModal
        name="file-upload-modal"
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      >
        <FileUpload submitCallback={handleFileUpload} />
      </WrappedModal>

      <Snackbar
        open={error}
        autoHideDuration={10000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
}
