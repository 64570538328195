import {
  Button,
  FormControlLabel,
  Grid,
  makeStyles,
  Snackbar,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import { apiCall } from "../../api/utils";
import { endpoints } from "../../api/_config";
import { Alert } from "@material-ui/lab";
import { func, string, bool, shape } from "prop-types";

const useStyle = makeStyles((theme) => ({
  textInput: {
    width: "100%",
  },
  inputWrap: {
    margin: `${theme.spacing(1)}px 0`,
  },
  submitButtonWrap: {
    textAlign: "right",
  },
}));

const propTypes = {
  handleClose: func.isRequired,
  activeProvider: shape({ name: string, id: string, enabled: bool }).isRequired,
};

const EditDataProvider = ({
  handleClose,
  activeProvider: { name, id, enabled } = {},
}) => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const [formInput, setFormInput] = useState({
    Name: name,
    Enabled: enabled,
  });
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const endpoint = endpoints.dataProviders.update.replace(":Id:", id);

      await apiCall(endpoint, formInput);
      handleClose();
    } catch (e) {
      setError(e.message);
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name) {
      setFormInput({ ...formInput, [name]: value });
    }
  };

  const handleCheckedChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    if (name) {
      setFormInput({ ...formInput, [name]: checked });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h2">Edit Data Provider</Typography>
      <Grid
        className={classes.inputWrap}
        container
        direction="column"
        spacing={1}
      >
        <Grid item xs>
          <TextField
            className={classes.textInput}
            id="name"
            name="Name"
            label="Data Provider"
            variant="outlined"
            value={formInput.Name}
            onChange={handleInputChange}
            required
          />
        </Grid>

        <Grid item xs>
          <FormControlLabel
            control={
              <Switch
                checked={formInput.Enabled}
                onChange={handleCheckedChange}
                name="Enabled"
                color="primary"
              />
            }
            label="Enabled"
          />
        </Grid>
        <Grid item xs className={classes.submitButtonWrap}>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save Changes
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={error} onClose={() => setError(null)}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </form>
  );
};

EditDataProvider.propTypes = propTypes;

export default EditDataProvider;
