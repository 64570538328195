import { Box, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { formatServerDate } from "../../utils/formatters";

const useStyles = makeStyles({
  date: {
    fontWeight: "bold",
  },
  creator: {
    fontSize: "0.7rem",
  },
});

const UploadSummary = (params) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={
        <Box display="flex" flexDirection="column" overflow="auto">
          <Typography>Name: {params.row.FileName}</Typography>
          <Typography>Status: {params.row.FileStatus}</Typography>
          <Typography>Provider: {params.row.ProviderName}</Typography>
        </Box>
      }
    >
      <Box>
        <Typography className={classes.date}>
          {formatServerDate(params.row.CreatedDate)}
        </Typography>
        <Typography className={classes.creator}>
          {params.row.CreatedBy}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default UploadSummary;
