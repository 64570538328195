import React, { useCallback, useEffect, useState } from "react";
import { arrayOf, shape, number, string, bool, func } from "prop-types";
import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { apiCall } from "../../api/utils";
import ToolBarWithCountdown from "./ToolbarWithCountdown";

const useStyle = makeStyles(() => ({
  datagrid: {
    minHeight: "300px",

    "& .row--filtered": {
      backgroundColor: "lightgrey",
      color: "darkgrey",

      "& svg": {
        color: "darkgrey",
      },
    },
  },
}));

const propTypes = {
  endpoint: string.isRequired,
  columnDefinitions: arrayOf(shape()).isRequired,
  idField: string.isRequired,
  pageSize: number,
  waitForInitialFilter: bool,
  initialFilter: shape({}),
  autoRefreshEnabled: bool,
  setAutoRefreshEnabled: func,
  autoRefreshInterval: number,
  triggeredRefresh: bool,
  options: shape({}),
  showAutoRefresh: bool,
  showColumnToolbar: bool,
  showFilterToolbar: bool,
};

const defaultProps = {
  pageSize: 5,
  waitForInitialFilter: false,
  initialFilter: { items: [] },
  autoRefresh: false,
  autoRefreshEnabled: false,
  setAutoRefreshEnabled: (r) => r,
  autoRefreshInterval: 10,
  triggeredRefresh: false,
  options: {},
  showAutoRefresh: false,
  showColumnToolbar: false,
  showFilterToolbar: false,
};

const WrappedDataGrid = ({
  endpoint,
  columnDefinitions,
  idField,
  pageSize,
  waitForInitialFilter,
  initialFilter,
  triggeredRefresh,
  showAutoRefresh,
  autoRefreshEnabled,
  autoRefreshInterval,
  setAutoRefreshEnabled,
  options,
  showColumnToolbar,
  showFilterToolbar,
}) => {
  if (!columnDefinitions || !idField || !endpoint) {
    console.error("[WrappedDataGrid] - Missing Required Fields", {
      columnDefinitions,
      idField,
      endpoint,
    });
    return null;
  }

  const classes = useStyle();
  const [rows, setRows] = useState([]);
  const [filterValue, setFilterValue] = useState(initialFilter);
  const [page, setPage] = useState(1);
  const [sortValue, setSortValue] = useState();
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toggleAutoRefresh = () => {
    setAutoRefreshEnabled((prev) => !prev);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const body = {
        filterInfo: filterValue,
        pageInfo: { page, pageSize },
        sortInfo: sortValue,
      };
      const { data, error } = await apiCall(endpoint, body);
      if (error) {
        setError(true);
      } else {
        if (typeof data.Data === "object" && typeof data.NumRows === "number") {
          setRowCount(data.NumRows);
          setRows(data.Data);
        } else {
          console.error(`[DataGrid] - Unexpected Data Recieved`, data);
          setError(true);
        }
      }

      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  useEffect(async () => {
    if (waitForInitialFilter && !filterValue) return;
    await getData();
  }, [filterValue, page, sortValue, triggeredRefresh, waitForInitialFilter]);

  const onFilterChange = useCallback((params) => {
    setRows([]); // rows are removed during change, otherwise it crashes
    setFilterValue(params.filterModel);
  }, []);

  const onPageChange = useCallback((page) => {
    setRows([]);
    setPage(page + 1); // server is 1 indexed
  }, []);

  const onSortModelChange = useCallback((params) => {
    setRows([]); // rows are removed during change, otherwise it crashes
    setSortValue(params.sortModel);
  }, []);

  return (
    <div data-testid="wrapped-data-grid">
      <DataGrid
        className={classes.datagrid}
        columns={columnDefinitions}
        rows={rows}
        rowCount={rowCount}
        getRowId={(row) => row[idField]}
        pageSize={pageSize}
        filterMode="server"
        filterModel={initialFilter}
        paginationMode="server"
        sortingMode="server"
        pagination
        onFilterModelChange={onFilterChange}
        onPageChange={onPageChange}
        onSortModelChange={onSortModelChange}
        components={{
          // eslint-disable-next-line react/display-name
          Toolbar: () => (
            <ToolBarWithCountdown
              playing={autoRefreshEnabled}
              seconds={autoRefreshInterval}
              onComplete={getData}
              showAutoRefresh={showAutoRefresh}
              showColumnToolbar={showColumnToolbar}
              showFilterToolbar={showFilterToolbar}
              toggleAutoRefresh={toggleAutoRefresh}
            />
          ),
        }}
        loading={loading}
        error={error}
        autoHeight
        disableSelectionOnClick
        {...options}
      />
    </div>
  );
};

WrappedDataGrid.defaultProps = defaultProps;
WrappedDataGrid.propTypes = propTypes;

export default WrappedDataGrid;
