import React from "react";
import { useMsal } from "@azure/msal-react";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  btnLogin: {
    fontWeight: 700,
    border: "2px solid #89016f",
    color: "#89016f",
    textTransform: "capitalize",
  },
}));

export const SignInButton = () => {
  const { instance } = useMsal();
  const { btnLogin } = useStyles();

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup();
    } else if (loginType === "redirect") {
      instance.loginRedirect();
    }
  };

  return (
    <Button
      className={btnLogin}
      onClick={() => handleLogin("redirect")}
      key="loginRedirect"
    >
      Log In
    </Button>
  );
};
