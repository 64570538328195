import React from "react";
import { Typography, Container } from "@material-ui/core";
import WrappedDataGrid from "../ui-components/WrappedDataGrid";
import { endpoints } from "../api/_config";
import { formatServerDate } from "../utils/formatters";
import Meta from "../ui-components/Meta";

export const columnDefs = [
  {
    field: "Date",
    headerName: "Date",
    valueFormatter: (param) => formatServerDate(param.value),
    width: 250,
  },
  { field: "Message", headerName: "Message", flex: 1 },
];

export function AuditLogs() {
  return (
    <Container maxWidth="lg">
      <Meta title="Audit Logs" />
      <Typography variant="h4">Audit Logs Test</Typography>
      <WrappedDataGrid
        columnDefinitions={columnDefs}
        endpoint={endpoints.auditLogs}
        idField="id"
      />
    </Container>
  );
}
