import React, { useCallback, useEffect, useState } from "react";
import { func } from "prop-types";
import filesize from "filesize";

import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import AddIcon from "@material-ui/icons/Add";

import { apiCall, uploadJsonFile } from "../../api/utils";
import { useDropzone } from "react-dropzone";
import { Alert } from "@material-ui/lab";
import { endpoints } from "../../api/_config";

const useStyle = makeStyles(() => ({
  dropzone: {
    minHeight: "200px",
    border: "2px solid #f4f4f4",
    borderRadius: "10px",
  },
  icon: {
    fontSize: "54px",
  },
}));

const propTypes = { submitCallback: func };
const defaultProps = { submitCallback: (r) => r };

const FileUpload = ({ submitCallback }) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);

  const [dataProviders, setDataProviders] = useState([]);
  const [file, setFile] = useState(null);

  const [formInput, setFormInput] = useState({
    dataProvider: "",
  });

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormInput({ ...formInput, [name]: value });
  };

  useEffect(async () => {
    const { data, error } = await apiCall(endpoints.dataProviders.all);
    if (error) {
      console.error(error);
      setError(error.message);
    } else {
      setDataProviders(data.Data);
    }
  }, []);

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      setFile(acceptedFiles[0]);
    } else if (acceptedFiles.length > 1) {
      setError("Unable to process multiple files, please upload one at a time");
    }
  }, []);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await uploadJsonFile({ ...formInput, file }, setProgress);
      submitCallback();
    } catch (e) {
      console.error(
        `[UPLOAD ERROR] - ${e?.response?.data?.title}`,
        e?.response?.data
      );

      setError(`${e.message} - See console for more information`);
    }
  };

  const handleReset = () => {
    setError(null);
    setProgress(0);
    setFormInput({
      dataProvider: null,
    });
  };

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: "application/json",
    multiple: false,
    maxFiles: 1,
    noClick: Boolean(file),
  });

  const classes = useStyle(isDragActive);

  return (
    <Container maxWidth="lg" disableGutters>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" style={{ marginBottom: "8px" }}>
          New Data Uploads
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs>
            <FormControl fullWidth>
              <InputLabel htmlFor="data-provider" variant="outlined">
                Data Provider
              </InputLabel>
              <Select
                id="upload-data-provider"
                name="dataProvider"
                value={formInput.dataProvider}
                onChange={handleInputChange}
                autoWidth
                label="Data Provider"
                variant="outlined"
                required
              >
                <MenuItem value="" disabled>
                  Please Select
                </MenuItem>
                {dataProviders.map(({ Id, Name }) => (
                  <MenuItem value={Id} key={Id} name={Name}>
                    {Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={1}
              className={classes.dropzone}
              {...getRootProps()}
            >
              <input
                id="upload-file"
                type="file"
                name="file"
                {...getInputProps()}
              />

              {!file && (
                <>
                  {isDragActive ? (
                    <>
                      <AddIcon className={classes.icon} />
                      <Typography variant="body1">
                        Drop the .json files here ...
                      </Typography>
                    </>
                  ) : (
                    <>
                      <DescriptionOutlinedIcon className={classes.icon} />

                      <Typography variant="body1">
                        Drag &amp; drop .json files here, or click to select
                        files
                      </Typography>
                    </>
                  )}
                </>
              )}
              {file && (
                <>
                  <Grid item>
                    <Box display="flex" alignItems="center">
                      <Box>
                        <DescriptionIcon className={classes.icon} />
                      </Box>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body2">{file.name}</Typography>
                        <Typography variant="body2">
                          {filesize(file.size)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid container spacing={1} justify="center">
                    {progress === 0 ? (
                      <>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            type="submit"
                          >
                            Upload
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleReset}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={8}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justify="center"
                        >
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                            color="primary"
                          />
                          <Typography
                            variant="body2"
                            align="center"
                          >{`Uploaded ${progress}%`}</Typography>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
              {error && (
                <Grid item>
                  <Alert
                    severity="error"
                    action={
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                    }
                  >
                    {error}
                  </Alert>
                </Grid>
              )}

              {fileRejections.map(({ file, errors }) => {
                console.log(file);
                return (
                  <Alert severity="warning" key={file.name}>
                    <Typography>{file.name}</Typography>
                    {errors.map(({ code, message }) => (
                      <Typography variant="body2" key={code}>
                        {message}
                      </Typography>
                    ))}
                  </Alert>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

FileUpload.propTypes = propTypes;
FileUpload.defaultProps = defaultProps;

export default FileUpload;
