import React, { useState } from "react";
import {
  Typography,
  Container,
  Button,
  Box,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import WrappedDataGrid from "../ui-components/WrappedDataGrid";
import { endpoints } from "../api/_config";
import WrappedModal from "../ui-components/WrappedModal";
import AddDataProvider from "../ui-components/DataProvider/AddDataProvider";
import EditDataProvider from "../ui-components/DataProvider/EditDataProvider";
import Meta from "../ui-components/Meta";

/* Todo: some kind of editable model binding to object? */

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
  },
}));
const DataProviders = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [activeProvider, setActiveProvider] = useState({
    name: "",
    id: "",
    enabled: false,
  });

  const handleClose = () => {
    setShowAdd(false);
    setShowEdit(false);
    setTriggerRefresh((prev) => !prev);
  };

  const EditActions = (params) => {
    const handleClick = () => {
      setActiveProvider({
        name: params.row.Name,
        id: params.row.Id,
        enabled: params.row.Enabled,
      });
      setShowEdit(true);
    };

    return (
      <Button onClick={handleClick} data-testid="button-edit">
        Edit
      </Button>
    );
  };

  const columnDefs = [
    { field: "Name", headerName: "Name", flex: 8 },
    { field: "Enabled", headerName: "Enabled", flex: 2 },
    {
      field: "Edit",
      headerName: " ",
      renderCell: EditActions,
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <>
      <Container maxWidth="lg">
        <Meta title="Data Providers" />
        <Box className={classes.sectionHeader}>
          <Typography variant="h2" data-testid="heading">
            Data Providers
          </Typography>
          <Button
            onClick={() => setShowAdd(true)}
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            data-testid="button-add"
          >
            Add Data Provider
          </Button>
        </Box>

        <WrappedDataGrid
          columnDefinitions={columnDefs}
          endpoint={endpoints.dataProviders.all}
          idField="Id"
          triggeredRefresh={triggerRefresh}
          data-testid="data-providers"
        />

        <WrappedModal
          open={showAdd}
          handleClose={handleClose}
          name="data-provider-add"
          data-testid="add-modal"
        >
          <AddDataProvider handleClose={handleClose} />
        </WrappedModal>
        <WrappedModal
          open={showEdit}
          handleClose={handleClose}
          name="data-provider-edit"
          data-testid="edit-modal"
        >
          <EditDataProvider
            handleClose={handleClose}
            activeProvider={activeProvider}
          />
        </WrappedModal>
      </Container>
    </>
  );
};

export default DataProviders;
