// If you need to support IE11 uncomment the imports below
//import "react-app-polyfill/ie11";
//import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "./styles/theme";
import { Provider } from "react-redux";
import App from "./App";

// MSAL imports
import { msalInstance } from "./api/msal";
import store from "./store/store";

// Account selection logic is app dependent. Adjust as needed for different use cases.

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={globalTheme}>
        <Provider store={store}>
          <App pca={msalInstance} />
        </Provider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
