import React from "react";
import { bool, number, func } from "prop-types";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import { Box, makeStyles, Typography, useTheme } from "@material-ui/core";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const useStyle = makeStyles((theme) => ({
  countdown: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  countdownText: {
    paddingLeft: theme.spacing(1),
    textTransform: "uppercase",
    fontSize: "14px",
  },
}));

const propTypes = {
  showFilterToolbar: bool,
  showColumnToolbar: bool,
  playing: bool,
  seconds: number,
  showAutoRefresh: bool,
  onComplete: func,
  toggleAutoRefresh: func,
};
const ToolBarWithCountdown = ({
  showFilterToolbar,
  showColumnToolbar,
  showAutoRefresh,
  toggleAutoRefresh,
  playing,
  seconds,
  onComplete,
}) => {
  const theme = useTheme();
  const classes = useStyle(theme);

  return (
    <GridToolbarContainer>
      {showFilterToolbar && <GridToolbarFilterButton />}
      {showColumnToolbar && <GridToolbarColumnsButton />}
      {showAutoRefresh && (
        <Box className={classes.countdown} onClick={toggleAutoRefresh}>
          <CountdownCircleTimer
            colors={[[theme.palette.primary.main, 1]]}
            duration={seconds}
            isPlaying={playing}
            onComplete={() => {
              onComplete();
              return [true, seconds * 1000];
            }}
            strokeWidth={6}
            size={24}
          />
          <Typography
            className={classes.countdownText}
            variant="body2"
            color="primary"
          >
            Auto Refresh
          </Typography>
        </Box>
      )}
    </GridToolbarContainer>
  );
};
ToolBarWithCountdown.propTypes = propTypes;
export default ToolBarWithCountdown;
