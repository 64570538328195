import { makeStyles, Tooltip } from "@material-ui/core";
import { string } from "prop-types";
import React from "react";

const useStyle = makeStyles({
  cell: {
    whiteSpace: (props) => props.whiteSpace, // to do - fix this
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "1rem",
  },
});

const propTypes = {
  value: string,
  whiteSpace: string,
};

const defaultProps = {
  whiteSpace: "break-space",
};

const ToolTipCell = ({ value, ...props }) => {
  const classes = useStyle(props);

  return (
    <Tooltip title={value} arrow placement="top">
      <span className={classes.cell}>{value}</span>
    </Tooltip>
  );
};

ToolTipCell.propTypes = propTypes;
ToolTipCell.defaultProps = defaultProps;

export default ToolTipCell;
