import React from "react";
import { string, number, func } from "prop-types";
import { Avatar, Chip, makeStyles, useTheme } from "@material-ui/core";

const useStyle = makeStyles({
  rootChip: ({ theme, color }) => ({
    backgroundColor: theme.palette[color].main,
    color: theme.palette[color].contrastText,
    marginRight: theme.spacing(1),
    "& .MuiChip-avatar": {
      color: theme.palette[color].contrastText,
      backgroundColor: theme.palette[color].dark,
      width: "unset",
      borderRadius: "16px",
      minWidth: "2rem",
      padding: "0px 8px",
      lineHeight: "1rem",
    },
    "&:hover": {
      backgroundColor: theme.palette[color].light,
    },
  }),
});

const propTypes = {
  label: string,
  number: number,
  onClick: func,
  color: string,
};

const defaultProps = {
  label: "Chip",
  number: 100,
  color: "primary",
};
const WrappedChip = (props) => {
  const { label, number, onClick, color } = props;
  const theme = useTheme();
  const classes = useStyle({ theme, color });

  return (
    <Chip
      className="wrapped-chip"
      classes={{ root: classes.rootChip }}
      label={label}
      avatar={<Avatar classes={classes.rootAvatar}>{number}</Avatar>}
      onClick={onClick}
    />
  );
};

WrappedChip.propTypes = propTypes;
WrappedChip.defaultProps = defaultProps;

export default WrappedChip;
