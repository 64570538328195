import React from "react";
import Header from "../Header";
import { node } from "prop-types";
import { Box, makeStyles } from "@material-ui/core";

const useStyle = makeStyles(() => ({
  wrapper: {
    marginTop: "90px" /*header height */,
  },
}));

const propTypes = {
  children: node.isRequired,
};

const PageLayout = ({ children }) => {
  const classes = useStyle();
  return (
    <>
      <Header />
      <Box className={classes.wrapper}>{children}</Box>
    </>
  );
};

PageLayout.propTypes = propTypes;

export default PageLayout;
