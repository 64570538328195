import {
  Box,
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import { func, string, bool } from "prop-types";

const useStyle = makeStyles((theme) => ({
  editRow: {
    marginBottom: theme.spacing(1),
    borderBottom: "1px solid lightgrey",
  },
}));

const propTypes = {
  onSave: func.isRequired,
  id: string.isRequired,
  onCancel: func,
  initialInput: string,
  initialEdit: bool,
  fieldLabel: string,
};

const defaultProps = {
  initialInput: false,
  initialEdit: false,
  fieldLabel: "Edit",
  onCancel: (r) => r,
};

const EditRow = ({
  onSave,
  onCancel,
  initialInput,
  initialEdit,
  id,
  fieldLabel,
}) => {
  const classes = useStyle();
  const [edit, setEdit] = useState(initialEdit);
  const [input, setInput] = useState(initialInput);

  const cancelEdit = () => {
    onCancel();
    setInput(initialInput);
    setEdit(false);
  };

  const startEdit = () => {
    setEdit(true);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await onSave(input, id);
    setEdit(false);
  };

  return (
    <Box key={id} className={classes.editRow}>
      <form onSubmit={handleSave}>
        <Grid container alignItems="center">
          <Grid item xs={9}>
            {edit ? (
              <TextField
                value={input}
                onChange={(e) => setInput(e.target.value)}
                autoFocus
                variant="outlined"
                label={fieldLabel}
                fullWidth
                required
              />
            ) : (
              <Typography>{input}</Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {edit ? (
                <>
                  <Button color="secondary" size="small" onClick={cancelEdit}>
                    <ClearIcon />
                  </Button>
                  <Button color="primary" size="small" type="submit">
                    <SaveIcon />
                  </Button>
                </>
              ) : (
                <Button color="primary" size="small" onClick={startEdit}>
                  <EditIcon />
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
EditRow.propTypes = propTypes;
EditRow.defaultProps = defaultProps;
export default EditRow;
