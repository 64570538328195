import React, { useState } from "react";

import { ChevronRight } from "@material-ui/icons";

import { Box, Button, makeStyles, useTheme } from "@material-ui/core";
import WrappedModal from "../WrappedModal";
import { shape, func } from "prop-types";
import EditClientAliases from "../Clients/EditClientAliases";
import EditClient from "../Clients/EditClient";

const useStyles = makeStyles(() => ({
  actionBox: {
    display: "flex",
    flexDirection: "column",

    "& Button": {
      textTransform: "capitalize",
      paddingLeft: 0,
      paddingBottom: 0,
      justifyContent: "flex-start",
    },
  },
}));

const propTypes = {
  param: shape({}).isRequired,
  handleRefresh: func,
};

const ClientActions = ({ param, handleRefresh }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [editClientOpen, setEditClientOpen] = useState(false);
  const [editAliasOpen, setEditAliasOpen] = useState(false);

  const handleClose = () => {
    setEditClientOpen(false);
    setEditAliasOpen(false);
  };

  return (
    <Box className={classes.actionBox}>
      <Button
        color="primary"
        endIcon={<ChevronRight />}
        onClick={() => setEditClientOpen(true)}
      >
        Edit Client
      </Button>
      <Button
        color="primary"
        endIcon={<ChevronRight />}
        onClick={() => setEditAliasOpen(true)}
      >
        Edit Aliases
      </Button>

      <WrappedModal
        name="edit-name"
        open={editClientOpen}
        handleClose={handleClose}
      >
        <EditClient
          handleClose={handleClose}
          handleUpdate={handleRefresh}
          activeClient={param.row}
        />
      </WrappedModal>

      <WrappedModal
        name="edit-alias"
        open={editAliasOpen}
        handleClose={handleClose}
      >
        <EditClientAliases
          activeClient={param.row}
          handleClose={handleClose}
          handleUpdate={handleRefresh}
        />
      </WrappedModal>
    </Box>
  );
};

ClientActions.propTypes = propTypes;

export default ClientActions;
