export const endpoints = {
  test: "/adminalert/GetAlerts",
  distributionAlerts: "",
  uploadJson: "/Data/UploadAlerts",
  locations: {
    all: "/Location/All",
    add: "/Location/Add",
    update: "/Location/:LocationId:/Update",
    getById: "/Location/:LocationId:/View",
    getByRetailerId: "/Location/ByRetailer/:RetailerId:/List",
  },
  clients: {
    all: "/Client/All",
    allOrganisations: "/Client/AllOrganisations",
    getOrgsUnits: "/Client/Org/:OrgId:/ListOrgUnits",
    addClient: "/Client/:OrgUnitId:/Add",
    addAlias: "/Client/:OrgUnitId:/AddAlias",
    updateAlias: "/Client/Alias/:AliasId:/Update",
    updateClient: "/Client/:ClientId:/Update",
  },
  dataProviders: {
    all: "/DataProviders/All",
    add: "/DataProviders/Add",
    update: "/DataProviders/:Id:/Update",
  },
  auditLogs: "/Data/GetAuditLogs",
  dashboard: "/Dashboard/GetDashboardStats",
  retailer: {
    all: "/Retailer/All",
    add: "/Retailer/Add",
    update: "/Retailer/:Id:/Update",
    addAlias: "/Retailer/:RetailerId:/AddAlias",
    addStoreType: "/Retailer/:RetailerId:/AddStoreType",
    updateAlias: "/Retailer/Alias/:AliasId:/Update",
    updateStoreType: "/Retailer/StoreType/:StoreTypeId:/Update",
  },
  upload: {
    pending: "/Data/NewUploads",
    history: "/Data/UploadHistory",
    markComplete: "/Data/:ImportRef:/MarkComplete",
    markCancelled: "/Data/:ImportRef:/MarkCancelled",
    valid: "/Data/:ImportRef:/GetValidAlerts",
    invalid: "/Data/:ImportRef:/GetInvalidAlerts",
    filtered: "/Data/:ImportRef:/GetFilteredAlerts",
    auditLogs: "/Data/:ImportRef:/GetAuditLogs",
    refresh: "/Data/:ImportRef:/RecheckMissingLinks",
  },
  data: {
    getRetailerActions: "/Data/GetRetailerActions",
    getClientActions: "/Data/GetClientActions",
    getStoreTypeActions: "/Data/GetStoreTypeActions",
  },
  qc: {
    getAlertQCInfo: "/Visit/:VisitID:/QC",
    postQCShowToClient: "/Visit/:VisitID:/QCComplete/Show",
    postQCHideFromClient: "/Visit/:VisitID:/QCComplete/Hide",
  },
};
