import { createSlice } from "@reduxjs/toolkit";

export const drawerAssistantSlice = createSlice({
  name: "drawerAssistant",
  initialState: {
    enabled: false,
    suggestions: [],
    info: [],
    buttons: [],
  },
  reducers: {
    enable: (state) => {
      state.enabled = true;
    },
    disable: (state) => {
      state.enabled = false;
    },
    setContent: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { enable, disable, setContent } = drawerAssistantSlice.actions;

export const selectDrawAssistant = (state) => state.drawerAssistant;

export default drawerAssistantSlice.reducer;
