import React from "react";

import { Grid, Typography, Container } from "@material-ui/core";
import { SignInButton } from "../ui-components/AuthHelpers/SignInButton";
import Meta from "../ui-components/Meta";

const UnauthenticatedPage = () => {
  return (
    <Container maxWidth="lg">
      <Meta title="Log In" />
      <Grid container direction="column" alignItems="center">
        <Grid item xs>
          <Typography variant="body1">
            Welcome, log in to get started
          </Typography>
        </Grid>
        <Grid item xs>
          <SignInButton />
        </Grid>
      </Grid>
    </Container>
  );
};

export default UnauthenticatedPage;
