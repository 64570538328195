import {
  Box,
  Button,
  Container,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { getDefaultHeaders } from "../api/utils";
import { endpoints } from "../api/_config";
import Meta from "../ui-components/Meta";

const QC = () => {
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const location = useLocation();

  const urlParams = new URLSearchParams(location?.search);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const headers = await getDefaultHeaders();

        const options = {
          headers: headers,
        };

        const endpoint = endpoints.qc.getAlertQCInfo.replace(
          ":VisitID:",
          params.id
        );
        const response = await axios.get(endpoint, options);

        if (response?.data?.Id) {
          setData(response?.data);
          setLoading(false);
        } else {
          setError("Invalid visit id");
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
        setError("Error fetching visit information");
        setLoading(false);
      }
    };
    getData();
  }, []);

  const handleApprove = async (showToClient) => {
    try {
      setLoading(true);

      const headers = await getDefaultHeaders();

      const options = {
        headers: headers,
      };

      const endpoint = showToClient
        ? endpoints?.qc.postQCShowToClient
        : endpoints?.qc.postQCHideFromClient;

      const path = endpoint.replace(":VisitID:", params.id);
      const response = await axios.post(path, null, options);

      if (response?.data?.Success) {
        setComplete(true);
      } else {
        setError(response?.data?.Message);
      }
      setLoading(false);
    } catch (e) {
      setError("Error Actioning Visit");
      setLoading(false);
    }
  };

  const wantedData = [
    "OrganisationalUnitName",
    "Product",
    "Retailer",
    "StoreName",
    "StorePostcode",
    "LegacyJobId",
    "LegacyShiftId",
  ];

  return (
    <Container maxWidth="lg">
      <Meta title="Dashboard" description="RedAlerts stats page" />
      <Box>
        {!params?.id && <>Missing visit id</>}
        {params?.id && (
          <>
            <Table>
              <TableRow>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>{urlParams.get("name") ?? "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Date</strong>
                </TableCell>
                <TableCell>{urlParams.get("visitDate") ?? "-"}</TableCell>
              </TableRow>
              {wantedData.map((field) => (
                <TableRow key={field}>
                  <TableCell>
                    <strong>{field}</strong>
                  </TableCell>
                  <TableCell>{data?.[field] ?? "-"}</TableCell>
                </TableRow>
              ))}
            </Table>

            <Box paddingTop="16px">
              {!complete && (
                <>
                  <Button
                    disabled={loading}
                    onClick={() => handleApprove(false)}
                    variant="outlined"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      marginRight: "8px",
                    }}
                  >
                    Hide from client
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={() => handleApprove(true)}
                    variant="contained"
                    style={{ backgroundColor: "green", color: "white" }}
                  >
                    Show to client
                  </Button>
                </>
              )}
              {complete && <Typography>Visit Actioned</Typography>}
              {error && (
                <Typography color="error">
                  Something went wrong: {error}
                </Typography>
              )}
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default QC;
