import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
  useTheme,
  Switch,
  OutlinedInput,
} from "@material-ui/core";
import React, { useState } from "react";
import { apiCall } from "../../api/utils";
import { endpoints } from "../../api/_config";
import { Alert } from "@material-ui/lab";
import { func, string, shape } from "prop-types";
import { Help } from "@material-ui/icons";
import WrappedDialog from "../WrappedDialog";

const useStyle = makeStyles((theme) => ({
  textInput: {
    width: "100%",
  },
  inputWrap: {
    margin: `${theme.spacing(1)}px 0`,
  },
  submitButtonWrap: {
    textAlign: "right",

    "& Button": {
      margin: theme.spacing(1),
    },
  },
}));

const propTypes = {
  handleClose: func.isRequired,
  handleUpdate: func.isRequired,
  activeClient: shape({ Name: string, Id: string }).isRequired,
};

const EditClient = ({
  handleClose,
  handleUpdate,
  activeClient: { Name, Id, Enabled, DaysAlertsValid } = {},
}) => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const [formInput, setFormInput] = useState({
    Name,
    Enabled,
    DaysAlertsValid,
  });
  const [error, setError] = useState(null);
  const [showDaysDialog, setShowDaysDialog] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        Name: formInput.Name.trim(),
        Enabled: formInput.Enabled,
        DaysAlertsValid: formInput.DaysAlertsValid,
      };

      const endpoint = endpoints.clients.updateClient.replace(":ClientId:", Id);

      const { error } = await apiCall(endpoint, body);
      if (error) {
        setError(error.message);
      } else {
        handleUpdate();
        handleClose();
      }
    } catch (e) {
      setError(e.message);
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name) {
      setFormInput({ ...formInput, [name]: value });
    }
  };

  const handleCheckedChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    if (name) {
      setFormInput({ ...formInput, [name]: checked });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h2">Edit Client</Typography>
      <Grid
        className={classes.inputWrap}
        container
        direction="column"
        spacing={1}
      >
        <Grid item xs>
          <TextField
            className={classes.textInput}
            id="name"
            name="Name"
            label="Client"
            variant="outlined"
            value={formInput.Name}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs>
          <FormControl fullWidth>
            <InputLabel required variant="outlined">
              Alert Expiry
            </InputLabel>
            <OutlinedInput
              className={classes.textInput}
              id="days"
              name="DaysAlertsValid"
              variant="outlined"
              value={formInput.DaysAlertsValid}
              onChange={handleInputChange}
              type="number"
              inputProps={{ min: 1, max: 365, step: 1 }}
              placeholder="1 to 365 days"
              required
              label="Alert Expiry"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowDaysDialog(true)}>
                    <Help />
                  </IconButton>
                  <WrappedDialog
                    open={showDaysDialog}
                    onClose={() => setShowDaysDialog(false)}
                    onCancel={() => setShowDaysDialog(false)}
                    cancelText="Okay"
                  >
                    <Typography>
                      After this many number of days, any alerts that have not
                      been approved by the client for us to complete will be
                      automatically hidden from view in readiness for the next
                      set of alerts to come through. <br />
                      <br /> In selecting this number, you should consider the
                      frequency of the data received for all retailers for this
                      client
                    </Typography>
                  </WrappedDialog>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={formInput.Enabled}
                  onChange={handleCheckedChange}
                  name="Enabled"
                  color="primary"
                />
              }
              label="Enabled"
            />
          </FormControl>
        </Grid>
        <Grid item xs className={classes.submitButtonWrap}>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={error} onClose={() => setError(null)}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </form>
  );
};

EditClient.propTypes = propTypes;

export default EditClient;
