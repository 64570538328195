import React, { useState } from "react";
import WrappedModal from "../WrappedModal";
import WrappedTableJSON from "../WrappedTableJSON";
import InfoIcon from "@material-ui/icons/Info";

const AlertDetails = (params) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <InfoIcon color="secondary" onClick={() => setOpen(true)} />
      <WrappedModal
        name="alert-details-modal"
        open={open}
        handleClose={() => setOpen(false)}
      >
        <WrappedTableJSON data={params.row} />
      </WrappedModal>
    </>
  );
};

export default AlertDetails;
