import React from "react";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  welcomeName: {
    fontFamily: '"Montserrat Alternates",sans-serif',
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },
}));

const WelcomeName = () => {
  const { accounts } = useMsal();
  const [name, setName] = useState(null);
  const theme = useTheme();
  const { welcomeName } = useStyles(theme);

  useEffect(() => {
    if (accounts.length > 0) {
      setName(accounts[0].name.split(" ")[0]);
    }
  }, [accounts]);

  if (name) {
    return (
      <Typography data-testid="welcome-name" className={welcomeName}>
        Welcome, {name}
      </Typography>
    );
  } else {
    return null;
  }
};

export default WelcomeName;
