import React from "react";
import { Typography, Container } from "@material-ui/core";

import WrappedDataGrid from "../ui-components/WrappedDataGrid";
import { endpoints } from "../api/_config";
import Meta from "../ui-components/Meta";

export function DistributionAlerts() {
  const columnDefs = [
    { field: "Id", headerName: "id", flex: 1, hide: true },
    { field: "ClientName", headerName: "Client", flex: 1 },
    { field: "Flag", headerName: "Flag", flex: 1, hide: true },
    { field: "Store", headerName: "Store", flex: 1 },
    { field: "Sku", headerName: "SKU", flex: 1 },
    { field: "ProductName", headerName: "Product", flex: 1 },
    { field: "IsExpired", headerName: "Is Expired", flex: 1, type: "checkbox" },
    {
      field: "IsInScope",
      headerName: "In RWW Scope",
      flex: 1,
      type: "checkbox",
    },
    { field: "IsInHirerScope", headerName: "In Hirer Scope", flex: 1 },
    { field: "lat", headerName: "lat", flex: 1, hide: true },
    { field: "lng", headerName: "lng", flex: 1, hide: true },
    { field: "Locality", headerName: "Locality", flex: 1, hide: true },
    { field: "LocalityType", headerName: "Locality Type", flex: 1, hide: true },
    { field: "Region", headerName: "Region", flex: 1 },
    {
      field: "LastSaleDate",
      headerName: "Last Sale Date",
      type: "date",
      flex: 2,
    },
    {
      field: "WeeksSinceLastSale",
      headerName: "Weeks Since Last Sale",
      type: "number",
      flex: 1,
    },
    {
      field: "LastStockOrSaleDate",
      headerName: "Last Stock Or Sale Date",
      type: "date",
      flex: 2,
    },
    {
      field: "WeeksSinceLastStockOrSale",
      headerName: "Weeks Since Stock Or Sale Date",
      type: "number",
      flex: 1,
    },
    {
      field: "StockOnHand",
      headerName: "Stock On Hand",
      type: "number",
      flex: 1,
    },
    {
      field: "AvgWeeklySales",
      headerName: "Avg Weekly Sales",
      type: "number",
      flex: 1,
    },
  ];

  return (
    <Container maxWidth="XLg">
      <Meta title="Distribution Alerts" />
      <Typography variant="h4">Distribution Alerts</Typography>
      <WrappedDataGrid
        columnDefinitions={columnDefs}
        idField="Id"
        endpoint={endpoints.distributionAlerts}
      />
    </Container>
  );
}
